import i18next from "i18next";
import { MessageBarType, Theme } from "@fluentui/react";
import { format, formatISO } from "date-fns";
import { toast } from "../components/FluentToast";
import { BackendError } from "../types/types";
import { mergeStyleSets } from "office-ui-fabric-react";
import moment from "moment";
import React from "react";
import { lte } from "lodash";

const defaultLSTaxonomyKey = "tmsTaxonomyCodes";
const gosUrl = process.env.REACT_APP_GOS_BASE_URL;

export const formatDateString = (date: string) => {
  return format(new Date(date), "yyyy.MM.dd");
};

export function logWithTimestamp(message: string): void {
  const timestamp = new Date().toISOString();
  console.log(`${timestamp}: ${message}`);
}

export const gosLinkTypeToPath = (
  linkType: "policy" | "client" | "payer",
  id: string,
  personType: string = "corporate"
): string => {
  let retVal = "";
  switch (linkType) {
    case "policy":
      retVal = gosUrl + "/policy/" + id + "?readOnly=true";
      break;
    case "client":
      retVal = gosUrl + "/crm/client/" + personType + "/" + id;
      break;
    case "payer":
      retVal = gosUrl + "/crm/client/" + personType + "/" + id;
      break;
    default:
      break;
  }
  return retVal;
};

export const getShortInsurerName = (idField: string): string => {
  switch (idField) {
    case "6176107019":
      return "ERGO";
    case "6176158376":
      return "Gjensidige";
    case "6176086372":
      return "BTA";
    case "6966267886":
      return "Balcia";
    case "6213123253":
      return "Compensa";
    case "6176113453":
      return "IF";
    case "6186812205":
      return "LD";
    default:
      return "";
  }
};

export const stringToNumber = (str: string) => {
  let _str = str;
  _str = _str.replace(/\./g, "");
  _str = String(_str).replace(",", ".");
  return parseFloat(_str);
};
export const firstLetterUpper = (string: string) => {
  return string && string.charAt(0).toUpperCase() + string.slice(1);
};
export const sanitizeFileName = (s: string) => {
  return s.replace(/[^a-z0-9]/gi, "_").toLowerCase();
};
export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
export const formatNumberForSearch = (value: number) => {
  if (typeof value === "number" && !isNaN(value)) {
    const locale = window.navigator.languages[0];

    const numberFormat = new Intl.NumberFormat(locale, {
      useGrouping: true,
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
    //const numberFormat = new Intl.NumberFormat(window.navigator.language);
    const displayValue = Number.isNaN(value) ? 0 : value;
    const text =
      Number.isNaN(displayValue) && displayValue === 0
        ? ""
        : numberFormat.format(displayValue);
    return text;
  }
  return "";
};
export function parseFloatBasedOnLocale(stringNumber) {
  const locale = navigator.language;
  const format = new Intl.NumberFormat(locale);
  const parts = format.formatToParts(12345.6);

  let groupSeparator;
  let decimalSeparator;

  for (let part of parts) {
    if (part.type === "group") {
      groupSeparator = part.value;
    }
    if (part.type === "decimal") {
      decimalSeparator = part.value;
    }
  }

  if (!decimalSeparator && !groupSeparator) {
    return parseFloat(stringNumber);
  }

  const sanitizedNumber = stringNumber
    .replace(new RegExp(`\\${groupSeparator}`, "g"), "")
    .replace(new RegExp(`\\${decimalSeparator}`), ".");

  return parseFloat(sanitizedNumber);
}
export function compareNumbersUpToTwoDecimals(num1, num2) {
  const n1 = Math.round(num1 * 100);
  const n2 = Math.round(num2 * 100);

  if (n1 === n2) return 0; // They are equal up to two decimals
  return n1 > n2 ? 1 : -1; // Returns 1 if num1 is larger, -1 if num2 is larger
}

export function copyToClipboard(text) {
  const textarea = document.createElement("textarea");
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.select();
  try {
    document.execCommand("copy");
    console.log("Text copied to clipboard");
  } catch (err) {
    console.error("Error copying text to clipboard", err);
  }
  document.body.removeChild(textarea);
}

export function isInvalidDate(date) {
  return (
    Object.prototype.toString.call(date) === "[object Date]" &&
    isNaN(date.getTime())
  );
}
export function prepareBankStatement(
  bankStatement: any,
  t: any,
  taxonomy: any
): any {
  return {
    companyRegisterNumber: bankStatement.companyRegisterNumber,
    msgId: bankStatement.msgId,
    statementCreatedAt: prepareDate(bankStatement.statementCreatedAt),
    statementId: bankStatement.statementId,
    accountId: bankStatement.accountId,
    accountCurrencyCode: prepareTaxonomy(
      bankStatement.accountCurrencyCode,
      t,
      taxonomy,
      "Currency"
    ),
    accountOwnerName: bankStatement.accountOwnerName,
    accountOwnerId: bankStatement.accountOwnerId,
    accountServicerName: bankStatement.accountServicerName,
    accountServicerBIC: bankStatement.accountServicerBIC,
    accountServicerId: bankStatement.accountServicerId,
    accountOPBD: prepareNumber(bankStatement.accountOPBD),
    accountCLBD: prepareNumber(bankStatement.accountCLBD),
    bankStatementComment: bankStatement.bankStatementComment,
  };
}
const formatter = new Intl.NumberFormat("de-DE", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});
export const currencyFormat = (num: number) => {
  if (!num && num !== 0) return;
  return formatter.format(num);
};

export function prepareBankStatementEntry(
  bankStatementEntry: any,
  t: any,
  taxonomy: any
): any {
  return {
    clientId: bankStatementEntry.clientId,
    clientName: bankStatementEntry.clientName,
    clientCode: bankStatementEntry.clientCode,
    clientAccountId: bankStatementEntry.clientAccountId,
    insuranceLineCode: bankStatementEntry.insuranceLineCode,
    amount: prepareNumber(bankStatementEntry.amount),
    paymentReferenceNumber: bankStatementEntry.paymentReferenceNumber,
    bankStatementEntryComment: bankStatementEntry.bankStatementEntryComment,
    accountId: bankStatementEntry.accountId,
    accountCurrencyCode: prepareTaxonomy(
      bankStatementEntry.accountCurrencyCode,
      t,
      taxonomy,
      "Currency"
    ),
    accountOwnerName: bankStatementEntry.accountOwnerName,
    valueDate: prepareDate(bankStatementEntry.valueDate),
    bookingDate: prepareDate(bankStatementEntry.bookingDate),
    companyRegisterNumber: bankStatementEntry.companyRegisterNumber,
  };
}

function prepareNumber(num: any) {
  return num + "";
  //return formatNumber(num, 2, 2);
}

function prepareTaxonomy1(value: any, t, taxonomy: any, taxonomyName: string) {
  const tax = taxonomy[taxonomyName].byId[value];
  return {
    value: tax.id,
    label: t(tax.code),
  };
}

function prepareTaxonomy(value: any, t, taxonomy: any, taxonomyName: string) {
  const tax = taxonomy[taxonomyName].byId[value];
  return tax.id;
}

function prepareDate(dateString: any) {
  return dateString ? moment(dateString).toDate() : null;
}

export const getClasses = (theme, isDarkMode) =>
  mergeStyleSets({
    navigation: {
      borderBottom: `1px solid ${theme.palette.neutralLight}`,
      height: "100%",
      "& .ms-Nav-groupContent": {
        marginBottom: "0",
      },
      ".ms-Nav-compositeLink.is-selected .ms-Button": {
        background: isDarkMode ? "#001321" : "#d0e7f8",
      },
    },
  });

export const getValueForServer = (type: string, value: any): any => {
  if (type === "date") {
    if (value === null || value === undefined) {
      return null;
    }
    return formatISO(value, { representation: "date" });
  }
  //
  else if (type === "number") {
    if (value === null || value === undefined) {
      return null;
    }
    return stringToNumber(value);
  }
  //
  else if (type === "selectlist") {
    if (value === null || value === undefined) {
      return null;
    }
    return value.value;
  } else if (type === "taxonomy") {
    if (value === null || value === undefined) {
      return null;
    }
    return value.value;
  } else if (type === "taxonomyapi") {
    if (value === null || value === undefined) {
      return null;
    }
    return value;
  }
  //
  else if (type === "taxonomylabel") {
    if (value === null || value === undefined) {
      return null;
    }
    if (typeof value === "string") {
      return value.trim();
    }
    //
    else {
      return value;
    }
  }
  //
  else if (type === "text") {
    if (value === null || value === undefined) {
      return null;
    }
    if (typeof value === "string") {
      return value.trim();
    }
    //
    else {
      return value;
    }
  }
  //
  else if (type === "checkbox") {
    return value;
  }
  return null;
};

export function deleteMissingProperties(sourceObject, referenceObject) {
  let retVal = { ...sourceObject };
  for (let key in retVal) {
    if (!(key in referenceObject)) {
      delete retVal[key];
    }
  }
  return retVal;
}

export const toBase64 = (file: File) => {
  const reader = new FileReader();

  return new Promise<string>((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing file"));
    };

    reader.onload = () => {
      resolve(
        reader.result
          ? typeof reader.result === "string"
            ? reader.result.substring(reader.result.indexOf(",") + 1)
            : undefined
          : undefined
      );
    };
    reader.readAsDataURL(file);
  });
};

export const getSelectStyles = (hasError: boolean, theme: Theme): any => {
  return {
    control: (p, s) => {
      return {
        ...p,
        border: 0,
        borderRadius: 0,
        borderBottom: "1px solid",
        borderBottomColor: s.isFocused
          ? hasError
            ? "red"
            : "#005aa1"
          : "#ccc",
        outline: "none",
        "&:hover": {
          borderBottomColor: s.isFocused
            ? hasError
              ? "red"
              : "#005aa1"
            : "#ccc",
        },
        backgroundColor: s.isDisabled ? theme.palette.white : undefined,
      };
    },
  };
};

export const prepareNotificationObject = (key, message: any): any => {
  if (Array.isArray(message)) {
    return message.map((item) => {
      return {
        errors: [],
        key: key,
        messageBarType: MessageBarType.error,
        fieldName: i18next.t("pct." + key + ".label"),
        handleNotificationClick: (e) => {
          // setFormStoreValues({
          //   mainTab: { ...mainTab, selectedTab: "offer" },
          //   offerTab: { ...offerTab, selectedTab: fieldToTab(key) },
          // });
        },
        notify: {
          label: "label",
          type: "error",
          notifyCode: "notifyCode",
          notifyText: (
            <>
              <b>{i18next.t("pct." + key + ".method")}</b>
              <br />
              {item}
            </>
          ),
        },
      };
    });
  } else {
    return {
      errors: [],
      key: key,
      messageBarType: MessageBarType.error,
      fieldName: i18next.t("pct." + key + ".label"),
      handleNotificationClick: (e) => {
        // setFormStoreValues({
        //   mainTab: { ...mainTab, selectedTab: "offer" },
        //   offerTab: { ...offerTab, selectedTab: fieldToTab(key) },
        // });
      },
      notify: {
        label: "label",
        type: "error",
        notifyCode: "notifyCode",
        notifyText: (
          <>
            <b>{i18next.t("pct." + key + ".method")}</b>
            <br />
            {message}
          </>
        ),
      },
    };
  }
};

export const handleAxiosError = (err?: any, callbackFunction?: any) => {
  if (err?.isAxiosError) {
    const response = err.response;
    if (response) {
      const responseData = err.response.data;
      if (responseData) {
        const env = process.env.REACT_APP_ENVIRONMENT!;
        const developmentEnvironments = ["L", "D", "T"];
        const isDevOrTestEnv = developmentEnvironments.includes(env);
        if (Array.isArray(responseData)) {
          const callbackFunctionList = [];
          responseData.forEach((e: BackendError) => {
            const translatedMessageVariables = e.messageVariables.map(
              (item: string) => {
                return i18next.t(item);
              }
            );
            let message = e.messageCode;
            if (isDevOrTestEnv) {
              message = e?.exceptionMessage || e?.formatedMessage;
              toast.error(i18next.t(message));
              callbackFunctionList.push(i18next.t(message));
            } else {
              toast.error(i18next.t(message, translatedMessageVariables));
              callbackFunctionList.push(
                i18next.t(message, translatedMessageVariables)
              );
            }
          });
          callbackFunction && callbackFunction(callbackFunctionList);
        }
        //
        else {
          let message = responseData.messageCode;
          if (message) {
            if (isDevOrTestEnv) {
              message =
                responseData?.exceptionMessage || responseData?.formatedMessage;
            }

            const translatedMessageVariables =
              responseData.messageVariables.map((item: string) => {
                return i18next.t(item);
              });
            toast.error(i18next.t(message, translatedMessageVariables));
            callbackFunction &&
              callbackFunction(i18next.t(message, translatedMessageVariables));
          } else {
            if (responseData.Message) {
              toast.error(responseData.Message);
              callbackFunction && callbackFunction(responseData.Message);
            } else {
              toast.error(responseData);
              callbackFunction && callbackFunction(responseData);
            }
          }
        }
      } else {
        toast.error(err?.toString());
        callbackFunction && callbackFunction(err?.toString());
      }
    } else {
      toast.error(err?.toString());
      callbackFunction && callbackFunction(err?.toString());
    }
  } else {
    toast.error(err?.toString());
    callbackFunction && callbackFunction(err?.toString());
  }
};
export const toNum = (value: string) => {
  return value && value !== ""
    ? Number.parseFloat(value.replaceAll(/\s/g, "").replaceAll(",", "."))
    : null;
};

export function numberValidation(
  yup: any,
  t: any,
  required: boolean,
  min: number | null,
  max: number | null
) {
  let retVal = yup
    .string()
    .nullable()
    .test("is-number", "Must be a number", (value) => {
      if (value === null || value === "") {
        return true;
      }
      // const germanNumberRegex = /^\d{1,3}(\.\d{3})*(,\d+)?$/;
      // if (!germanNumberRegex.test(value)) {
      //   return false;
      // }
      const huLocale = "hu-HU";
      const parsedNumber = Number(
        value.replaceAll(/\s/g, "").replace(",", ".")
      );
      const formattedNumber = parsedNumber.toLocaleString(huLocale);
      return !isNaN(parsedNumber); // && formattedNumber === value;
    })
    .transform((value) => (value === "" ? null : value));

  if (required) {
    retVal = retVal.required();
  }
  if (min) {
    retVal = retVal.test(
      "min",
      t("greco.form.errors.number.greaterorequal", { min }),
      (value) => {
        if (value === null) {
          return true;
        }
        const parsedNumber = Number(
          value.replaceAll(/\s/g, "").replace(",", ".")
        );
        return parsedNumber >= min;
      }
    );
  }
  if (max) {
    retVal = retVal.test(
      "max",
      t("greco.form.errors.number.lessorequal", { max }),
      (value) => {
        if (value === null) {
          return true;
        }
        const parsedNumber = Number(
          value.replaceAll(/\s/g, "").replace(",", ".")
        );
        return parsedNumber <= max;
      }
    );
  }
  return retVal;
}

export function flattenObject(obj, prefix = "") {
  return Object.keys(obj).reduce((acc, key) => {
    const newKey = prefix ? `${prefix}.${key}` : key;
    if (typeof obj[key] === "object" && obj[key] !== null) {
      Object.assign(acc, flattenObject(obj[key], newKey));
    } else {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
}

export const getTaxonomyCodesFromLocalStorage = (
  lsKey: string = defaultLSTaxonomyKey,
  taxonomyName?: string
) => {
  const lsTaxCodes = localStorage.getItem(lsKey);

  if (!lsTaxCodes) {
    return undefined;
  }

  const taxCodes = JSON.parse(lsTaxCodes);

  if (taxonomyName) {
    // returns array for given taxonomy code name or undefined if given name doesn't exist
    return taxCodes[taxonomyName];
  }

  // returns whole taxonomy object with all taxonomy code arrays
  return taxCodes;
};

export const getTaxonomyItemId = (
  taxonomyName: string,
  taxonomyItemCode: string,
  lsKey: string = defaultLSTaxonomyKey
) => {
  const taxCodes = getTaxonomyCodesFromLocalStorage(lsKey, taxonomyName);
  console.log(taxCodes, "TAX CODES");

  if (!taxCodes) {
    return undefined;
  }

  const findItem = taxCodes.filter((taxItem: any) => {
    return taxItem.code === taxonomyItemCode;
  });

  return findItem.length ? findItem[0].id : undefined;
};

export const formatDate = (date: Date | undefined): string =>
  moment(date).format("DD.MM.YYYY")!;

export const maxNumberOfSearchResults = 100000;

export const pageSize = 30;
export const defaultSort = { field: "sortableEventDateTime", dir: "desc" };
export const panelTypes = {
  COLUMN_OPTIONS: "COLUMN_OPTIONS",
};
