import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Input,
} from "@fluentui/react-components";
import { ArrowSplitFilled } from "@fluentui/react-icons";
import React from "react";
import { useTranslation } from "react-i18next";

import moment from "moment";
import { useAppStore } from "store/AppStore";
import { useClientBalancePageStore } from "store/ClientBalancePageStore";
import ClearanceMenubar from "./ClearanceMenubar";
import { compareNumbersUpToTwoDecimals } from "utils/utils";
import Alert from "components/Alert";

export const ClearClientSaldoDialog = () => {
  const [
    {
      clearClientSaldoDialog,
      clearClientSaldoStatus,
      clientSaldoAmount,
      clientSaldo,
      clTotal,
      clUnsettledPaymentPlans,
      clearanceRequest,
      clPaymentDate,
      clComment,
    },
    { clearClientSaldo, setClientBalancePageStoreValues, loadClientBalances },
  ] = useClientBalancePageStore();

  const [{ taxonomy, updatedGrecoCompanies, grecoCompanies }] = useAppStore();
  const isLoading = clearClientSaldoStatus === "loading";
  const { t } = useTranslation();
  const [openDateAfterAlert, setOpenDateAfterAlert] = React.useState(false);

  return (
    <>
      <Dialog open={clearClientSaldoDialog.isOpen}>
        <DialogSurface style={{ maxWidth: "96%", transform: "none" }}>
          <DialogBody>
            <DialogTitle>
              {t("pct.clearClientSaldo.label")}{" "}
              {clientSaldo?.isGrECoClient ? t("pct.discounts.label") : null} -{" "}
              {clearClientSaldoDialog.clientName}
            </DialogTitle>
            <DialogContent>
              <ClearanceMenubar />
            </DialogContent>
            <DialogActions>
              <Button
                appearance="secondary"
                disabled={isLoading}
                onClick={() => {
                  setClientBalancePageStoreValues({
                    clearClientSaldoDialog: {
                      isOpen: false,
                      clientName: "",
                    },
                    clInsurersCountryCode: null,
                    clInsurersSelectedItem: null,
                    clPayersSelectedItem: null,
                    clSearchTerm: "",
                    clServiceSegmentCode: null,
                    clUnsettledPaymentPlans: [],
                    clUnsettledPaymentPlanMetadataMap: {},
                    clTotal: 0,
                    clTotalRows: 0,
                    clearanceRequest: null,

                    clFromDateFilter: moment()
                      .subtract(3, "months")
                      .add(15, "days")
                      .startOf("day")
                      .toDate(),
                    clToDateFilter: moment()
                      .endOf("day")
                      .add(15, "days")
                      .toDate(),
                  });
                }}
              >
                {t("pct.close.label")}
              </Button>
              <Button
                appearance="primary"
                disabled={
                  isLoading ||
                  compareNumbersUpToTwoDecimals(clTotal, clientSaldoAmount) >
                    0 ||
                  clUnsettledPaymentPlans.length === 0
                }
                icon={<ArrowSplitFilled fontSize={16} />}
                onClick={() => {
                  if (moment(clPaymentDate).isAfter(moment())) {
                    setOpenDateAfterAlert(true);
                    return;
                  }

                  const suggestedPayments = clUnsettledPaymentPlans
                    ?.filter(
                      (el) =>
                        el.paymentPlanEntryId &&
                        !el.isDeleted &&
                        el.suggestedPaymentAmount > 0
                    )
                    .map((el) => {
                      return {
                        ClientId: el.clientId,
                        InsurerId: el.insurerId,
                        PolicyId: el.policyId,
                        PaymentPlanEntryId: el.paymentPlanEntryId,
                        InsuranceLineCode: el.insuranceLineCode,
                        BookingDate: el.bookingDate,
                        DocumentDate: el.documentDate,
                        PremiumFrom: el.premiumFrom,
                        PremiumTo: el.premiumTo,
                        PremiumAmount: el.suggestedPaymentAmount,
                        CurrencyCode: el.currencyCode,
                        PaymentComment: el.paymentComment,
                      };
                    });
                  clearClientSaldo(
                    {
                      clientId: clientSaldo.clientId,
                      clearanceRequest: clearanceRequest,
                      suggestedPayments,
                      comment: clComment,
                      paymentDate: clPaymentDate,
                    },
                    () => {
                      setClientBalancePageStoreValues({
                        clearClientSaldoDialog: {
                          isOpen: false,
                          clientName: "",
                        },
                        clInsurersCountryCode: null,
                        clInsurersSelectedItem: null,
                        clPayersSelectedItem: null,
                        clSearchTerm: "",
                        clServiceSegmentCode: null,
                        clUnsettledPaymentPlans: [],
                        clUnsettledPaymentPlanMetadataMap: {},
                        clTotal: 0,
                        clTotalRows: 0,
                        clientSaldo: null,
                        clearanceRequest: null,

                        clFromDateFilter: moment()
                          .subtract(3, "months")
                          .add(15, "days")
                          .startOf("day")
                          .toDate(),
                        clToDateFilter: moment()
                          .endOf("day")
                          .add(15, "days")
                          .toDate(),
                      });
                      // getClientSaldo({
                      //   clientId: clientSaldo.clientId,
                      // });
                      loadClientBalances({
                        grecoCompanyNumbers: updatedGrecoCompanies,
                        t,
                        grecoCompanies,
                        taxonomy,
                      });
                    }
                  );
                }}
              >
                {t("pct.clear.label")}
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
      <Alert
        title={t("pct.paymentDateAfterAlertTitle.label")}
        content={t("pct.paymentDateAfterAlert.label")}
        opened={openDateAfterAlert}
        setOpened={setOpenDateAfterAlert}
      />
    </>
  );
};
