import { ColumnConfig } from "types/reminders";

export const createReminderColumnConfig = (
  scaleFactor: number = 1
): ColumnConfig[] => {
  return [
    {
      columnId: "ordinalNumber",
      width: scaleFactor * 50,
      reorderable: false,
      resizable: true,
      label: "",
      cellType: "ordinalnumber",
      sortable: false,
    },
    {
      columnId: "policyName",
      width: scaleFactor * 250,
      reorderable: true,
      resizable: true,
      sortable: true,
      label: "pct.policyName.label",
      cellType: "customtext",
      additionalInfo: {
        gosLink: {
          idField: "policyId",
          linkType: "policy",
        },
      },
    },
    {
      columnId: "insurerName",
      width: scaleFactor * 250,
      reorderable: true,
      resizable: true,
      sortable: true,
      label: "pct.insurerName.label",
      cellType: "customtext",
      additionalInfo: {
        gosLink: {
          idField: "insurerId",
          linkType: "client",
        },
      },
    },

    {
      columnId: "payerName",
      width: scaleFactor * 145,
      reorderable: true,
      resizable: true,
      sortable: true,
      label: "pct.payer.label",
      cellType: "customtext",
      additionalInfo: {
        gosLink: {
          idField: "payerId",
          linkType: "payer",
        },
      },
    },
    {
      columnId: "policyNumber",
      width: scaleFactor * 150,
      reorderable: true,
      resizable: true,
      sortable: true,
      label: "pct.policyNumber.label",
      cellType: "customtext",
      additionalInfo: undefined,
    },

    {
      columnId: "policySubNumber",
      width: scaleFactor * 150,
      reorderable: true,
      resizable: true,
      sortable: true,
      label: "pct.policySubNumber.label",
      cellType: "customtext",
      additionalInfo: undefined,
    },
    {
      columnId: "insuranceLineName",
      width: scaleFactor * 250,
      reorderable: true,
      resizable: true,
      sortable: true,
      label: "pct.insuranceLineCode.label",
      cellType: "customtext",
      additionalInfo: undefined,
    },
    {
      columnId: "comment",
      width: scaleFactor * 350,
      reorderable: true,
      resizable: true,
      sortable: true,
      label: "pct.comment.label",
      cellType: "customtext",
      additionalInfo: undefined,
    },
    {
      columnId: "bookingDate",
      width: scaleFactor * 120,
      reorderable: true,
      resizable: true,
      sortable: true,
      label: "pct.bookingDate.label",
      cellType: "datepicker",
      additionalInfo: undefined,
    },
    {
      columnId: "premiumFrom",
      width: scaleFactor * 120,
      reorderable: true,
      resizable: true,
      sortable: true,
      label: "pct.premiumFrom.label",
      cellType: "datepicker",
      additionalInfo: undefined,
    },
    {
      columnId: "premiumTo",
      width: scaleFactor * 120,
      reorderable: true,
      resizable: true,
      sortable: true,
      label: "pct.premiumTo.label",
      cellType: "datepicker",
      additionalInfo: undefined,
    },
    // {
    //   columnId: "policyPaymentFrequencyCodeId",
    //   width: scaleFactor * 150,
    //   reorderable: true,
    //   resizable: true,
    //   label: "pct.policyPaymentFrequencyCodeId.label",
    //   cellType: "taxonomy",
    //   additionalInfo: {
    //     taxonomy: "PaymentFrequency",
    //   },
    // },

    {
      columnId: "premiumAmount",
      width: scaleFactor * 120,
      reorderable: true,
      resizable: true,
      sortable: true,
      label: "pct.premiumAmount.label",
      cellType: "customnumber",
      additionalInfo: undefined,
    },
    {
      columnId: "premiumDiscount",
      width: scaleFactor * 120,
      reorderable: true,
      resizable: true,
      sortable: true,
      label: "pct.premiumDiscount.label",
      cellType: "customnumber",
      additionalInfo: undefined,
    },
    {
      columnId: "premiumDiscountPercent",
      width: scaleFactor * 120,
      reorderable: true,
      resizable: true,
      sortable: true,
      label: "pct.premiumDiscountPercent.label",
      cellType: "customnumber",
      additionalInfo: undefined,
    },
    {
      columnId: "totalPremiumAmount",
      width: scaleFactor * 120,
      reorderable: true,
      resizable: true,
      sortable: true,
      label: "pct.totalPremiumAmount.label",
      cellType: "customnumber",
      additionalInfo: undefined,
    },
    {
      columnId: "paidPremiumAmount",
      width: scaleFactor * 120,
      reorderable: true,
      resizable: true,
      sortable: true,
      label: "pct.paidPremiumAmount.label",
      cellType: "customnumber",
      additionalInfo: undefined,
    },
    {
      columnId: "premiumDebt",
      width: scaleFactor * 120,
      reorderable: true,
      resizable: true,
      sortable: true,
      label: "pct.premiumDebt.label",
      cellType: "customnumber",
      additionalInfo: undefined,
    },

    {
      columnId: "select",
      width: scaleFactor * 40,
      reorderable: false,
      resizable: false,
      label: "",
      cellType: "select",
      sortable: false,
      additionalInfo: {
        rowIdField: "paymentPlanEntryId",
      },
    },
  ];
};
