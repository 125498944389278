import axios, { CancelToken } from "axios";
import { ExpandedColumn } from "../types/columns";
import {
  Setting,
  SettingsResponse,
  TaxonomyUADResponse,
  TaxonomyResponse,
  GrecoCompany,
} from "../types/types";
import { createAzureFilterParam } from "../utils/azureSearchFilterParam";
import moment from "moment";

const url = process.env.REACT_APP_BASE_URL;
const uadUrl = process.env.REACT_APP_BFF_HOST_UAD;
const crmUrl = process.env.REACT_APP_BASE_URL_CRMV2;

export const clientSearch = async (body) => {
  let endpointUrl = `${crmUrl}/search`;

  const res = await axios.post<any>(endpointUrl, body);
  return res;
};

export const insurerSearch = async (body) => {
  let endpointUrl = `${crmUrl}/search`;

  const res = await axios.post<any>(endpointUrl, body);
  return res;
};

export const getInsurersByCountry = async (countryCodeId) => {
  let endpointUrl = `${crmUrl}/search`;

  const res = await axios.post<any>(endpointUrl, {
    search: "",
    top: null,
    skip: null,
    orderby: ["partnerName asc"],
    filter: "countryCodeID eq " + countryCodeId,
    partnerType: [900000000003142],
    select: [
      "partnerID",
      "partnerName",
      "partnerNumber",
      "countryCodeID",
      "isDeleted",
    ],
  });
  return res;
};

export const loadClientSaldos = async (body) => {
  let endpointUrl = `${url}/payment/saldo`;

  const res = await axios.post<any>(endpointUrl, body);
  return res;
};

export const gosLastSyncTime = async () => {
  let endpointUrl = `${url}/goslastsynctime`;

  const res = await axios.get<any>(endpointUrl);
  return res;
};

type PaymentPlanEntryArgs = {
  vehicleId?: number;
  vehicleStatusCode?: number;
  skip: number;
  top: number;
  search?: string;
  sort: any;
  cancelToken: CancelToken;
  columns: ExpandedColumn[];
  clientIds?: number[];
  isBrokerPremiumCollection?: any;
};

export const paymentPlanEntry = async (args: PaymentPlanEntryArgs) => {
  const {
    search,
    sort,
    top,
    skip,
    cancelToken,
    columns,
    isBrokerPremiumCollection,
  } = args;

  let searchUrl = `${url}/paymentplanentry?a=1`;

  // if (vehicleId) {
  //   searchUrl += `?fleetId=${vehicleId}`;
  // } else {
  //   searchUrl += "?a=1";
  // }

  let searchString = search;
  if (searchString) {
    searchString = searchString.includes("-")
      ? `"${searchString}"`
      : searchString;
  }
  searchUrl += `&searchCriteria=${searchString?.replace(
    /&/g,
    encodeURIComponent("&")
  )}*`;

  if (sort && sort.key !== "" && sort.dir !== "") {
    let sortKey = sort.key;
    switch (sortKey) {
      case "payer":
        sortKey = "payerName";
        break;
      case "client":
        sortKey = "clientName";
        break;
    }

    searchUrl += `&$orderby=${sortKey} ${sort.dir}`;
  }
  searchUrl += `&form=json`;
  searchUrl += `&$count=true`;
  searchUrl += `&$top=${top}&$skip=${skip}`;

  if (isBrokerPremiumCollection) {
    searchUrl += `&isBrokerPremiumCollection=${
      isBrokerPremiumCollection === "yes" ? true : false
    }`;
  }

  let filterStr = createAzureFilterParam(columns as any);

  if (filterStr.length > 0) {
    searchUrl += `&$filter=${filterStr}`;
  }

  const res = await axios.get<any>(searchUrl, {
    cancelToken,
  });

  return res;
};

export const paymentExcelInsurerReport = async (args) => {
  const { insurerId, from, to, clientId } = args;

  let searchUrl = `${url}/payment/generateExcel?a=1`;

  if (insurerId && insurerId !== "") {
    searchUrl += `&insurerId=${from}`;
  }

  if (from && from !== "") {
    searchUrl += `&from=${from}`;
  }

  if (to && to !== "") {
    searchUrl += `&to=${to}`;
  }
  if (clientId && clientId !== "") {
    searchUrl += `&clientId=${clientId}`;
  }
  const res = await axios.get<any>(searchUrl);

  return res;
};

export const populateBankStatementEntryInfo = async (args) => {
  const { bankStatementEntryId } = args;

  let endpointUrl = `${url}/payment/bankstatemententry/${bankStatementEntryId}`;
  const res = await axios.get<any>(endpointUrl);
  return res;
};

export const suggestBankStatementEntryClearance = async (args) => {
  const {
    bankStatementId,
    bankStatementEntryId,
    clientId,
    ServiceSegmentCodes,
    InsurerIds,
    ClientIds,
    PayerIds,
    From,
    To,
    CurrencyCode,
    MaxResults,
    PolicyNumbers,
    PolicySubnumbers,
    PaymentReferenceNumbers,
    IsBrokerPremiumCollection,
  } = args;

  let endpointUrl = `${url}/bankstatemententry/suggestunsettled/${bankStatementId}/${bankStatementEntryId}/${clientId}`;

  let query = {};
  if (MaxResults) {
    query = {
      ...query,
      MaxResults,
    };
  }

  if (PolicyNumbers) {
    query = {
      ...query,
      PolicyNumbers,
    };
  }

  if (IsBrokerPremiumCollection !== null) {
    query = {
      ...query,
      IsBrokerPremiumCollection,
    };
  }
  if (PolicySubnumbers) {
    query = {
      ...query,
      PolicySubnumbers,
    };
  }
  if (PaymentReferenceNumbers) {
    query = {
      ...query,
      PaymentReferenceNumbers,
    };
  }
  if (CurrencyCode) {
    query = {
      ...query,
      CurrencyCode,
    };
  }

  if (ServiceSegmentCodes) {
    query = {
      ...query,
      ServiceSegmentCodes,
    };
  }
  if (InsurerIds) {
    query = {
      ...query,
      InsurerIds,
    };
  }
  if (ClientIds) {
    query = {
      ...query,
      ClientIds,
    };
  }
  if (PayerIds) {
    query = {
      ...query,
      PayerIds,
    };
  }
  if (From) {
    query = {
      ...query,
      From,
    };
  }
  if (To) {
    query = {
      ...query,
      To,
    };
  }
  const res = await axios.post<any>(endpointUrl, query);
  return res;
};

export const suggestReminder = async (args) => {
  const {
    clientId,
    insurerIds,
    payerIds,
    serviceSegmentCodes,
    policyNumbers,
    policySubnumbers,
    from,
    to,
    isBrokerPremiumCollection,
    includeIfAlreadyInReminder,
    currencyCode,
    maxResults,
    grecoCompanyNumbers,
  } = args;

  let endpointUrl = `${url}/reminder/suggest/${clientId}`;

  let query = {};
  if (maxResults) {
    query = {
      ...query,
      maxResults,
    };
  }

  query = {
    ...query,
    isBrokerPremiumCollection,
  };
  query = {
    ...query,
    includeIfAlreadyInReminder,
  };
  if (currencyCode) {
    query = {
      ...query,
      currencyCode,
    };
  }
  if (policyNumbers) {
    query = {
      ...query,
      policyNumbers,
    };
  }
  if (policySubnumbers) {
    query = {
      ...query,
      policySubnumbers,
    };
  }
  if (grecoCompanyNumbers) {
    query = {
      ...query,
      grecoCompanyNumbers,
    };
  }

  if (clientId) {
    query = {
      ...query,
      clientIds: [clientId],
    };
  }
  if (serviceSegmentCodes) {
    query = {
      ...query,
      serviceSegmentCodes,
    };
  }
  if (insurerIds) {
    query = {
      ...query,
      insurerIds,
    };
  }
  if (payerIds) {
    query = {
      ...query,
      payerIds,
    };
  }
  query = {
    ...query,
    from: from
      ? from
      : moment(new Date()).subtract(100, "years").format("YYYY-MM-DD"),
  };

  query = {
    ...query,
    to: to ? to : moment(new Date()).add(100, "years").format("YYYY-MM-DD"),
  };

  const res = await axios.post<any>(endpointUrl, query);
  return res;
};

export const suggestClientSaldoClearance = async (args) => {
  const {
    clientId,
    clientIds,
    serviceSegmentCodes,
    insurerIds,
    payerIds,
    From,
    To,
    currencyCode,
    MaxResults,
    policyNumbers,
    policySubnumbers,
    PaymentReferenceNumbers,
    GrecoCompanyNumbers,
    isBrokerPremiumCollection,
  } = args;

  let endpointUrl = `${url}/payment/suggestunsettled/${clientId}`;

  let query = {};
  if (MaxResults) {
    query = {
      ...query,
      MaxResults,
    };
  }

  query = {
    ...query,
    IsBrokerPremiumCollection: isBrokerPremiumCollection,
  };

  if (currencyCode) {
    query = {
      ...query,
      CurrencyCode: currencyCode,
    };
  }
  if (clientIds) {
    query = {
      ...query,
      ClientIds: clientIds,
    };
  }

  if (payerIds) {
    query = {
      ...query,
      PayerIds: payerIds,
    };
  }
  if (PaymentReferenceNumbers) {
    query = {
      ...query,
      PaymentReferenceNumbers,
    };
  }
  if (GrecoCompanyNumbers) {
    query = {
      ...query,
      GrecoCompanyNumbers,
    };
  }

  if (policyNumbers) {
    query = {
      ...query,
      PolicyNumbers: policyNumbers,
    };
  }
  if (policySubnumbers) {
    query = {
      ...query,
      PolicySubnumbers: policySubnumbers,
    };
  }
  if (serviceSegmentCodes) {
    query = {
      ...query,
      ServiceSegmentCodes: serviceSegmentCodes,
    };
  }
  if (insurerIds) {
    query = {
      ...query,
      InsurerIds: insurerIds,
    };
  }
  if (From) {
    query = {
      ...query,
      From,
    };
  }
  if (To) {
    query = {
      ...query,
      To,
    };
  }
  const res = await axios.post<any>(endpointUrl, query);
  return res;
};

export const paymentPlanEntryUnsettled = async (args) => {
  const {
    clientId,
    insurerId,
    serviceSegmentCode,
    policyNumber,
    from,
    to,
    currencyCode,
    maxResults,
  } = args;

  let endpointUrl = `${url}/paymentplanentry/unsettled`;

  let query = {};
  if (maxResults) {
    query = {
      ...query,
      maxResults,
    };
  }

  if (currencyCode) {
    query = {
      ...query,
      currencyCode,
    };
  }
  if (policyNumber) {
    query = {
      ...query,
      policyNumber,
    };
  }
  if (clientId) {
    query = {
      ...query,
      clientId,
    };
  }
  if (serviceSegmentCode) {
    query = {
      ...query,
      serviceSegmentCode,
    };
  }
  if (insurerId) {
    query = {
      ...query,
      insurerId,
    };
  }
  if (from) {
    query = {
      ...query,
      from,
    };
  }
  if (to) {
    query = {
      ...query,
      to,
    };
  }
  const res = await axios.post<any>(endpointUrl, query);
  return res;
};

export const commitBankStatementEntryClearance = async (args) => {
  const {
    bankStatementId,
    bankStatementEntryId,
    clientId,
    bankStatementEntry,
    suggestedPayments,
    paymentPlanRequest,
  } = args;

  let endpointUrl = `${url}/bankstatemententry/${bankStatementId}/${bankStatementEntryId}/${clientId}`;

  let query = {
    BankStatementEntry: bankStatementEntry,
    SuggestedPayments: suggestedPayments,
    PaymentPlanRequest: paymentPlanRequest,
  };

  const res = await axios.post<any>(endpointUrl, query);
  return res;
};

export const populateClientBalanceClearanceInfo = async (args) => {
  const { clientId, companyNumber, currencyCode } = args;

  let endpointUrl = `${url}/payment/saldo/history/${clientId}/${companyNumber}/${currencyCode}`;
  const res = await axios.get<any>(endpointUrl);
  return res;
};

export const commitClientSaldoClearance = async (args) => {
  const {
    clientId,
    clearanceRequest,
    suggestedPayments,
    comment,
    paymentDate,
  } = args;

  let endpointUrl = `${url}/payment/${clientId}`;

  let query = {
    ClearanceRequest: clearanceRequest,
    SuggestedPayments: suggestedPayments,
    Comment: comment,
    PaymentDate: paymentDate ? moment(paymentDate).format("YYYY-MM-DD") : null,
  };

  const res = await axios.post<any>(endpointUrl, query);
  return res;
};

export const bankStatementQuery = async (args: any) => {
  const { grecoCompanies, From, To, DateFilterField } = args;

  let searchUrl = `${url}/bankstatement/query`;
  const res = await axios.post<any>(searchUrl, {
    CompanyRegisterNumbers: grecoCompanies,
    From,
    To,
    DateFilterField,
  });
  return res;
};

export const paymentQuery = async (args: any) => {
  let searchUrl = `${url}/payment/query`;
  const res = await axios.post<any>(searchUrl, {
    ...args,
  });
  return res;
};

export const generateExcel = async (args: any) => {
  let searchUrl = `${url}/payment/generateExcel`;
  const res = await axios.post<any>(searchUrl, {
    ...args,
  });
  return res;
};

export const declarePayments = async (args: any) => {
  let searchUrl = `${url}/payment/declaretoinsurer`;
  const res = await axios.post<any>(searchUrl, {
    ...args,
  });
  return res;
};

export const declarePaymentsManually = async (args: any) => {
  let searchUrl = `${url}/payment/declaretoinsurer/manually`;
  const res = await axios.post<any>(searchUrl, {
    ...args,
  });
  return res;
};

export const reminderQuery = async (args: any) => {
  let searchUrl = `${url}/reminder/query`;
  const res = await axios.post<any>(searchUrl, { ...args });
  return res;
};

export const deleteBankStatement = async ({
  bankStatementId,
  comment,
  rowVersion,
}) => {
  return await axios.patch<any>(
    `${url}/bankstatement/deletion/${bankStatementId}`,
    {
      Comment: comment,
      RowVersion: rowVersion,
    }
  );
};

export const synchronizeGos = async () => {
  return await axios.patch<any>(`${url}/synchronize/gos`);
};

export const invalidatePayments = async (args) => {
  return await axios.patch<any>(`${url}/payment/invalidate/multiple`, {
    ...args,
  });
};

export const deleteReminder = async ({
  reminderId,
  clientId,
  comment,
  rowVersion,
}) => {
  return await axios.patch<any>(`${url}/reminder/${clientId}/${reminderId}`, {
    Comment: comment,
    RowVersion: rowVersion,
  });
};

export const importBankStatement = async ({ name, base64String }) => {
  return await axios.post<any>(`${url}/bankstatement/import`, {
    Name: name,
    Base64String: base64String,
  });
};

export const createBankStatement = async (bankStatement) => {
  return await axios.post<any>(`${url}/bankstatement`, bankStatement);
};

export const createReminder = async (clientId, reminder) => {
  return await axios.post<any>(`${url}/reminder/${clientId}`, reminder);
};

export const getReminder = async (clientId, reminderId) => {
  return await axios.get<any>(`${url}/reminder/${clientId}/${reminderId}`);
};

export const updateBankStatement = async ({
  bankStatement,
  bankStatementId,
}) => {
  return await axios.patch<any>(
    `${url}/bankstatement/${bankStatementId}`,
    bankStatement
  );
};

export const updateReminder = async ({ clientId, reminder, reminderId }) => {
  return await axios.patch<any>(
    `${url}/reminder/${clientId}/${reminderId}`,
    reminder
  );
};

export const createBankStatementEntry = async (
  bankStatementId,
  bankStatementEntry
) => {
  return await axios.post<any>(
    `${url}/bankstatemententry/${bankStatementId}`,
    bankStatementEntry
  );
};

export const getBankStatementEntry = async ({
  bankStatementId,
  bankStatementEntryId,
}) => {
  return await axios.get<any>(
    `${url}/bankstatemententry/${bankStatementId}/${bankStatementEntryId}`
  );
};

export const updateBankStatementEntry = async ({
  bankStatementEntry,
  bankStatementId,
  bankStatementEntryId,
}) => {
  return await axios.patch<any>(
    `${url}/bankstatemententry/${bankStatementId}/${bankStatementEntryId}`,
    bankStatementEntry
  );
};

export const updateReminderEntry = async ({
  clientId,
  reminderEntry,
  reminderId,
  reminderEntryId,
}) => {
  return await axios.patch<any>(
    `${url}/reminderentry/${clientId}/${reminderId}/${reminderEntryId}`,
    reminderEntry
  );
};

export const deleteBankStatementEntry = async ({
  bankStatementId,
  bankStatementEntryId,
  comment,
  rowVersion,
}) => {
  return await axios.patch<any>(
    `${url}/bankstatemententry/deletion/${bankStatementId}/${bankStatementEntryId}`,
    {
      Comment: comment,
      RowVersion: rowVersion,
    }
  );
};

export const deleteReminderEntry = async ({
  clientId,
  reminderId,
  reminderEntryId,
  comment,
  rowVersion,
}) => {
  return await axios.patch<any>(
    `${url}/reminderentry/${clientId}/${reminderId}/${reminderEntryId}`,
    {
      Comment: comment,
      RowVersion: rowVersion,
    }
  );
};

export const bankStatementGet = async (args: any) => {
  const { bankStatementId } = args;

  let searchUrl = `${url}/bankstatement/${bankStatementId}`;
  const res = await axios.get<any>(searchUrl);

  return res;
};

export const getClientSaldo = async (args: any) => {
  const { clientId } = args;

  let searchUrl = `${url}/payment/${clientId}`;
  const res = await axios.get<any>(searchUrl);

  return res;
};

export const resolvementSuggest = async (args: any) => {
  const { bankStatementId, bankStatementEntryId } = args;

  let searchUrl = `${url}/bankstatemententry/resolvement/${bankStatementId}/${bankStatementEntryId}`;
  const res = await axios.get<any>(searchUrl);

  return res;
};

export const resolvementSuggestForStatement = async (args: any) => {
  const { bankStatementId } = args;

  let searchUrl = `${url}/bankstatement/resolvement/${bankStatementId}`;
  const res = await axios.get<any>(searchUrl);

  return res;
};

export const resolvementManual = async (args: any) => {
  const { bankStatementId, bankStatementEntryId, clientId } = args;

  let searchUrl = `${url}/bankstatemententry/resolvement/${bankStatementId}/${bankStatementEntryId}`;
  const res = await axios.patch<any>(searchUrl, {
    ClientId: clientId,
  });

  return res;
};

export const searchClients = async (firstLetters: string) => {
  return await axios.get<any>(`${url}/clientssearch/${firstLetters}`);
};

export const searchInsurers = async (firstLetters: string) => {
  return await axios.get<any>(`${url}/insurerssearch/${firstLetters}`);
};

export const getAppSettings = async (appID: number) => {
  return await axios.get<SettingsResponse>(`${uadUrl}/appsettings/${appID}`);
};

export const getGrecoCompanies = async () => {
  return await axios.get<GrecoCompany[]>(`${url}/grecocompany`);
};
export const getUADTaxonomy = async () => {
  return await axios.get<TaxonomyUADResponse>(`${uadUrl}/taxonomy`);
};

export const getTaxonomy = async () => {
  return await axios.get<TaxonomyResponse>(`${url}/taxonomy`);
};

export const addAppSetting = async (setting: Partial<Setting>) => {
  return await axios.post<Setting[]>(`${uadUrl}/addappsettings`, [setting]);
};

export const deleteAppSetting = async (
  userAppSettingId: Setting["userAppSettingId"]
) => {
  return await axios.delete<Setting[]>(`${uadUrl}/deleteappsettings`, {
    data: [userAppSettingId],
  });
};

export const updateAppSetting = async (setting: any) => {
  return await axios.patch<Setting[]>(`${uadUrl}/updateappsettings`, [setting]);
};
