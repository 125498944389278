import AsyncSelect from "react-select/async";
import debounce from "debounce-promise";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";

import { searchClients } from "api/api";
import { useRemindersStore } from "store/RemindersStore";
import { useAppStoreState } from "store/AppStore";
import { useRemindersCreateDialogStore } from "store/RemindersCreateDialogStore";

const SearchPayers = () => {
  const { t } = useTranslation();
  const { payerId } = useParams() as any;
  const [
    { clPayersSelectedItem, createReminderStatus },
    { setRemindersCreateDialogStoreValue },
  ] = useRemindersCreateDialogStore();

  const isDisabled = !!payerId || createReminderStatus === "loading";
  const { appState } = useAppStoreState();

  const customStyles = {
    menu: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: appState.darkMode ? "#222222" : "white", // Or any desired color
      color: appState.darkMode ? "white" : "#222222", // Or any desired color
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      // minHeight: "30px", // reduce the height
      // height: "30px",
      // padding: "0 6px",

      backgroundColor: appState.darkMode ? "#222222" : "white", // Or any desired color
      color: appState.darkMode ? "white" : "white", // Or any desired color
    }),
    valueContainer: (provided) => ({
      ...provided,
      // height: "30px",
      // padding: "0 6px",
      // alignItems: "center", // vertically center the text
    }),
    input: (baseStyles, state) => ({
      ...baseStyles,
      color: appState.darkMode ? "white" : "#222222",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: appState.darkMode ? "white" : "#222222",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? appState.darkMode
            ? "#000000"
            : "#eeeeee"
          : undefined,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? appState.darkMode
            ? "white"
            : "#222222"
          : appState.darkMode
          ? "white"
          : "#222222",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "yellow"
              : appState.darkMode
              ? "white"
              : "white"
            : undefined,
        },
      };
    },
  };
  return (
    <>
      <AsyncSelect
        isMulti
        id="payersSearchWide"
        loadOptions={debounce(async (input) => {
          const trimmedInput = input.trim();
          if (trimmedInput.length === 0) return [];
          const payers = await searchClients(trimmedInput);
          return payers.data.map((c) => {
            return {
              label:
                c.name + (c.internalNumber ? ` (${c.internalNumber})` : ""),
              value: c.id,
            };
          });
        }, 300)}
        styles={customStyles}
        menuPlacement={"auto"}
        placeholder={t("pct.searchPayers.label")}
        noOptionsMessage={() => t("greco.noResults")}
        isClearable
        isDisabled={isDisabled}
        loadingMessage={() => t("greco.searching")}
        value={clPayersSelectedItem}
        onChange={(value) => {
          setRemindersCreateDialogStoreValue("clPayersSelectedItem", value);
        }}
      />
    </>
  );
};

export default SearchPayers;
