import { TextCell, TextCellTemplate } from "@silevis/reactgrid";
import {
  CustomTextCell,
  CustomTextCellTemplate,
} from "../customcells/CustomTextCellTemplate";
import { ICellTypeHandler } from "components/grecoreactgrid/ICellTypeHandler";
import { gosLinkTypeToPath } from "utils/utils";

class CustomTextCellTypeHandler implements ICellTypeHandler {
  cellType = "customtext";
  isCustom = true;
  instance = new CustomTextCellTemplate();
  determineNewValue = (change: any) => {
    return (change.newCell as CustomTextCell).text;
  };
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    let entryValue = { ...entry };
    let gosLink = null;
    if (columnConfig.additionalInfo && columnConfig.additionalInfo.gosLink) {
      const linkType = columnConfig.additionalInfo.gosLink.linkType;
      let personType: string = "corporate";
      if (linkType === "client") {
        if (entryValue[columnConfig.columnId]) {
          if ((entryValue[columnConfig.columnId] as string).startsWith(" ")) {
            personType = "private";
          }
          if (
            entryValue["clientInfo"]?.["isCompany"] &&
            entryValue["clientInfo"]?.["isCompany"] === false
          ) {
            personType = "private";
          }
        }
      } else if (linkType === "payer") {
        if (entryValue[columnConfig.columnId] === null) {
          personType = "private";
          if (entryValue.payerName !== null) {
            entryValue[columnConfig.columnId] = entryValue.payerName;
          } else {
            if (entryValue.payerLastName !== null) {
              entryValue[columnConfig.columnId] =
                " " +
                entryValue.payerLastName +
                " " +
                entryValue.payerFirstName +
                " (" +
                entryValue.payerInternalNumber +
                ")";
            } else {
              entryValue[columnConfig.columnId] = null;
            }
          }
        }
      }
      const idField = columnConfig.additionalInfo.gosLink.idField;
      gosLink = gosLinkTypeToPath(linkType, entryValue[idField], personType);
      console.log(
        linkType,
        personType,
        gosLink,
        entryValue[columnConfig.columnId]
      );
    }
    return {
      type: "customtext",
      text: entryValue[columnConfig.columnId]
        ? entryValue[columnConfig.columnId].trim()
        : "",
      editable: rowsMetadataMap[entryValue[keyColumn]]?.editable ?? true,
      selected: rowsMetadataMap[entryValue[keyColumn]]?.selected ?? false,
      highlighted: rowsMetadataMap[entryValue[keyColumn]]?.highlighted ?? false,
      gosLink: gosLink,
    };
  };
}

export default CustomTextCellTypeHandler;
