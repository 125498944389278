import { defaultDatePickerStrings, useTheme } from "@fluentui/react";
import { Button, Radio, RadioGroup, Spinner } from "@fluentui/react-components";
import { DatePicker, IDatePicker } from "@fluentui/react-datepicker-compat";
import { SearchRegular } from "@fluentui/react-icons";

import moment from "moment";
import { makeStyles } from "office-ui-fabric-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppStore } from "store/AppStore";
import { useClientBalancePageStore } from "store/ClientBalancePageStore";
import { isInvalidDate } from "utils/utils";
import SearchCurrencyCodes from "./SearchCurrencyCodes";
import SearchInsurers from "./SearchInsurers";
import SearchPolicies from "./SearchPolicies";
import SearchServiceSegments from "./SearchServiceSegments";
import ClearanceEntriesGrid from "./ClearanceEntriesGrid";
import SearchSubpolicies from "./SearchSubpolicies";
import SearchPaymentReferenceNumbers from "./SearchPaymentReferenceNumbers";
import SearchClients from "./SearchClients";
import { VSpace } from "components/Spacer";
import SearchInsurerCountry from "./SearchInsurerCountry";
import SearchPayers from "./SearchPayers";

const onFormatDate = (date?: Date): string => {
  return !date
    ? ""
    : date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear();
};

const ClearanceMenubar = (props) => {
  const [
    {
      clFromDateFilter,
      clToDateFilter,
      clearClientSaldoStatus,
      clServiceSegmentsSelectedItem,
      clInsurersSelectedItem,
      clPoliciesSelectedItem,
      clSubpoliciesSelectedItem,
      clPaymentReferenceNumbersSelectedItem,
      clClientsSelectedItem,
      clPayersSelectedItem,
      clIsBrokerPremiumCollection,
      clientSaldo,
      clGrecoCompanyNumbers,
      clCurrencyCodesSelectedItem,
    },
    { setClientBalancePageStoreValue, populateClearanceData },
  ] = useClientBalancePageStore();
  const [{ taxonomy }] = useAppStore();
  const { t } = useTranslation();
  const theme = useTheme();

  const fromDatePickerRef = React.useRef<IDatePicker>(null);
  const toDatePickerRef = React.useRef<IDatePicker>(null);

  const onParseFromDateFromString = React.useCallback(
    (newValue: string): Date => {
      const previousValue = clFromDateFilter || new Date();
      const newValueParts = (newValue || "").trim().split(".");
      const day =
        newValueParts.length > 0
          ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10)))
          : previousValue.getDate();
      const month =
        newValueParts.length > 1
          ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
          : previousValue.getMonth();
      let year =
        newValueParts.length > 2
          ? parseInt(newValueParts[2], 10)
          : previousValue.getFullYear();

      return new Date(year, month, day);
    },
    [clFromDateFilter]
  );

  const onParseToDateFromString = React.useCallback(
    (newValue: string): Date => {
      const previousValue = clToDateFilter || new Date();
      const newValueParts = (newValue || "").trim().split(".");
      const day =
        newValueParts.length > 0
          ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10)))
          : previousValue.getDate();
      const month =
        newValueParts.length > 1
          ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
          : previousValue.getMonth();
      let year =
        newValueParts.length > 2
          ? parseInt(newValueParts[2], 10)
          : previousValue.getFullYear();

      return new Date(year, month, day);
    },
    [clToDateFilter]
  );
  const useStyles = makeStyles({
    control: {
      maxWidth: "250px",
    },
    clDialogContainer: {
      display: "flex",
      height: "calc(100vh - 150px)",
      width: "calc(100vw - 350px)",
    },

    clDialogSidebar: {
      flex: "0 0 400px",
      backgroundColor: theme.palette.neutralLighter,
      padding: "20px",
      boxShadow: "2px 0 5px rgba(0,0,0,0.1)",
    },

    clDialogItem: {
      marginBottom: "10px",
      padding: "10px",
      backgroundColor: theme.palette.neutralLight,
      borderRadius: "4px",
      transition: "background-color 0.3s",
    },

    "clDialogItem:hover": {
      backgroundColor: "#d4d4d4",
    },

    clDialogContent: {
      flex: 1,
      padding: "0px 0px 0px 20px",
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },

    clDialogSection: {
      padding: "20px",
      backgroundColor: theme.palette.neutralLighter,
      borderRadius: "4px",
    },
  });
  const styles = useStyles();
  const isLoading = clearClientSaldoStatus === "loading";
  return (
    <>
      <div className={styles.clDialogContainer}>
        <div className={styles.clDialogSidebar}>
          <div className={styles.clDialogItem}>
            <div style={{ display: "flex" }}>
              <div style={{ flexGrow: 1 }}>
                <DatePicker
                  className={styles.control}
                  onError={(e) => {
                    return;
                  }}
                  onErrorCapture={(e) => {
                    return;
                  }}
                  componentRef={fromDatePickerRef}
                  allowTextInput
                  value={clFromDateFilter}
                  onSelectDate={(date: Date | undefined) => {
                    if (
                      moment(date?.getTime()).format("YYYY-MM-DD") !==
                      moment(clFromDateFilter?.getTime()).format("YYYY-MM-DD")
                    ) {
                      setClientBalancePageStoreValue("clFromDateFilter", date);
                    } else {
                      setClientBalancePageStoreValue("clFromDateFilter", null);
                    }
                  }}
                  parseDateFromString={(dateStr) =>
                    onParseFromDateFromString(dateStr)
                  }
                  strings={defaultDatePickerStrings}
                  disableAutoFocus={true}
                  formatDate={onFormatDate}
                  onBlur={(e) => {
                    const inputEl: any =
                      e.currentTarget.childNodes[0].childNodes[0].childNodes[0]
                        .childNodes[0];
                    setTimeout(() => {
                      inputEl.blur();
                      const val = onParseFromDateFromString(inputEl.value);
                      if (
                        !isInvalidDate(val) &&
                        moment(val.getTime()).format("YYYY-MM-DD") !==
                          moment(clFromDateFilter?.getTime()).format(
                            "YYYY-MM-DD"
                          )
                      )
                        setClientBalancePageStoreValue(
                          "clFromDateFilter",
                          isNaN(val.getTime()) ? null : val
                        );
                    }, 300);
                  }}
                  placeholder="Select from date..."
                  disabled={isLoading}
                />
              </div>
              <div style={{ flexGrow: 1 }}>
                <DatePicker
                  className={styles.control}
                  onError={(e) => {
                    return;
                  }}
                  onErrorCapture={(e) => {
                    return;
                  }}
                  componentRef={toDatePickerRef}
                  allowTextInput
                  value={clToDateFilter}
                  onSelectDate={(date: Date | undefined) => {
                    if (
                      moment(date?.getTime()).format("YYYY-MM-DD") !==
                      moment(clToDateFilter?.getTime()).format("YYYY-MM-DD")
                    ) {
                      setClientBalancePageStoreValue("clToDateFilter", date);
                    } else {
                      setClientBalancePageStoreValue("clToDateFilter", null);
                    }
                  }}
                  parseDateFromString={onParseToDateFromString}
                  strings={defaultDatePickerStrings}
                  disableAutoFocus={true}
                  formatDate={onFormatDate}
                  onBlur={(e) => {
                    const inputEl: any =
                      e.currentTarget.childNodes[0].childNodes[0].childNodes[0]
                        .childNodes[0];
                    setTimeout(() => {
                      inputEl.blur();
                      const val = onParseFromDateFromString(inputEl.value);
                      if (
                        !isInvalidDate(val) &&
                        moment(val.getTime()).format("YYYY-MM-DD") !==
                          moment(clToDateFilter?.getTime()).format("YYYY-MM-DD")
                      ) {
                        setClientBalancePageStoreValue(
                          "clToDateFilter",
                          isNaN(val.getTime()) ? null : moment(val).toDate()
                        );
                      }
                    }, 300);
                  }}
                  disabled={isLoading}
                  placeholder="Select to date..."
                />
              </div>
            </div>
          </div>
          <div className={styles.clDialogItem}>
            {" "}
            <SearchClients />
            {clClientsSelectedItem.length === 0 ? (
              <span style={{ color: "crimson" }}>
                {t("pct.error.ClientEmpty.txt")}
              </span>
            ) : null}
          </div>
          <div className={styles.clDialogItem}>
            <SearchInsurerCountry />
            <VSpace height={10} />
            <SearchInsurers />
          </div>
          <div className={styles.clDialogItem}>
            <SearchPayers />
          </div>
          <div className={styles.clDialogItem}>
            <SearchPaymentReferenceNumbers />
          </div>
          <div className={styles.clDialogItem}>
            <SearchCurrencyCodes />
          </div>
          <div className={styles.clDialogItem}>
            <SearchServiceSegments />
          </div>
          <div className={styles.clDialogItem}>
            <SearchPolicies />
          </div>
          <div className={styles.clDialogItem}>
            <SearchSubpolicies />
          </div>
          {clientSaldo?.isGrECoClient ? (
            <div className={styles.clDialogItem}>
              {t("pct.isBrokerPremiumCollection.label")}:{" "}
              <RadioGroup
                disabled={isLoading ? true : false}
                layout="horizontal"
                onChange={(e, data) =>
                  setClientBalancePageStoreValue(
                    "clIsBrokerPremiumCollection",
                    data.value === "null"
                      ? null
                      : data.value === "true"
                      ? true
                      : false
                  )
                }
                value={clIsBrokerPremiumCollection + ""}
              >
                <Radio value="true" label={t("greco.yes")} />
                <Radio value="false" label={t("greco.no")} />
                <Radio value="null" label={t("pct.selectall.label")} />
              </RadioGroup>
            </div>
          ) : null}
          <div style={{ textAlign: "right", marginTop: "30px" }}>
            <Button
              appearance="primary"
              icon={<SearchRegular />}
              onClick={(e) => {
                let params = {
                  t: t,
                  clientId: clientSaldo?.clientId,
                  clientIds: clClientsSelectedItem
                    ? clClientsSelectedItem.map((i) => i.value)
                    : null,

                  taxonomy,
                  fromDate: clFromDateFilter,
                  toDate: clToDateFilter,
                  serviceSegmentCodes: clServiceSegmentsSelectedItem
                    ? clServiceSegmentsSelectedItem.map((i) => i.value)
                    : null,
                  searchTerm: null,
                  currencyCode: clCurrencyCodesSelectedItem.value,
                  policyNumbers: clPoliciesSelectedItem
                    ? clPoliciesSelectedItem.map((i) => i.value)
                    : null,
                  policySubnumbers: clSubpoliciesSelectedItem
                    ? clSubpoliciesSelectedItem.map((i) => i.value)
                    : null,
                  insurerIds: clInsurersSelectedItem
                    ? clInsurersSelectedItem.map((i) => i.value)
                    : null,
                  payerIds: clPayersSelectedItem
                    ? clPayersSelectedItem.map((i) => i.value)
                    : null,
                  paymentReferenceNumbers: clPaymentReferenceNumbersSelectedItem
                    ? clPaymentReferenceNumbersSelectedItem.map((i) => i.value)
                    : null,
                  isBrokerPremiumCollection: clIsBrokerPremiumCollection,
                  grecoCompanyNumbers: clGrecoCompanyNumbers,
                };
                if (!clientSaldo?.isGrECoClient) {
                  delete params.isBrokerPremiumCollection;
                }
                populateClearanceData(params);
              }}
              disabled={
                isLoading ||
                clClientsSelectedItem === null ||
                clClientsSelectedItem.length === 0
              }
            >
              {t("pct.search.label")}
            </Button>
          </div>
        </div>
        <div className={styles.clDialogContent}>
          <div className={styles.clDialogSection}>
            {!isLoading ? (
              <ClearanceEntriesGrid />
            ) : (
              <div
                style={{
                  width: "calc(100vw - 670px)",
                  height: "calc(100vh - 200px)",
                  paddingTop: "calc(100vh - 600px)",
                }}
              >
                <Spinner label={t("greco.loading")} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ClearanceMenubar;
