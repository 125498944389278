import { BankStatementEntryOptionsCellTemplate } from "pages/BankStatements/BankStatementEntriesGrid/customcells/BankStatementEntryOptionsCellTemplate";
import { ICellTypeHandler } from "../../../../components/grecoreactgrid/ICellTypeHandler";
import { TextCell } from "@silevis/reactgrid";

class BankStatementEntryOptionsCellTypeHandler implements ICellTypeHandler {
  cellType = "bankstatemententryoptions";
  isCustom = true;
  instance = new BankStatementEntryOptionsCellTemplate();
  determineNewValue = (change: any) => (change.newCell as TextCell).text;
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    const [
      {
        bankStatements,
        bankStatementEntries,
        bankStatementEntryMetadataMap,
        clFromDateFilter,
        clToDateFilter,
      },
      {
        setBankStatementsStoreValues,
        saveBankStatementEntry,
        cancelEditBankStatementEntry,
        resolvementSuggest,
        populateClearanceData,
        populateBankStatementEntryInfoData,
      },
    ] = context["bankStatementsStore"];
    const { t, taxonomy } = context;
    const bankStatement = bankStatements?.find((st) => {
      return st.bankStatementId === entry["bankStatementId"];
    });

    return {
      type: "bankstatemententryoptions", //,
      rowId: columnConfig.additionalInfo?.rowIdField
        ? entry[columnConfig.additionalInfo.rowIdField]
        : "",
      link: columnConfig.additionalInfo?.linkField
        ? entry[columnConfig.additionalInfo.linkField]
        : "",
      clientId: entry["clientId"],
      deleteFn: !bankStatement?.bankStatementDocumentPath
        ? (rowId) => {
            setBankStatementsStoreValues({
              deleteBankStatementEntryDialog: {
                isOpen: true,
                bankStatementEntryId: entry["bankStatementEntryId"],
                bankStatementId: entry["bankStatementId"],
              },
            });
          }
        : undefined,
      saveFn: (rowId) => {
        saveBankStatementEntry(rowId);
      },
      cancelFn: (rowId) => {
        cancelEditBankStatementEntry(rowId);
      },
      clearFn: (rowId) => {
        let referenceNumbers = [];
        let referenceNumber = null;
        const isGrEcoPayment = entry["isGrEcoPayment"];
        if (entry["paymentReferenceNumber"]) {
          referenceNumbers.push({
            label: entry["paymentReferenceNumber"],
            value: entry["paymentReferenceNumber"],
          });
          referenceNumber = entry["paymentReferenceNumber"];
        }
        let client = null;
        if (!isGrEcoPayment) {
          client = {
            label:
              entry["clientInfo"]["name"] +
              " (" +
              entry["clientInfo"]["internalNumber"] +
              ")",
            value: entry["clientInfo"]["id"],
          };
        }
        if (entry["amount"] > 0) {
          setBankStatementsStoreValues({
            clearBankStatementEntryDialog: {
              isOpen: true,
              bankStatementEntryId: entry["bankStatementEntryId"],
              bankStatementId: entry["bankStatementId"],
              insuranceLineCode: entry["insuranceLineCode"],
            },
            clearBankStatementEntrySort: null,
            clPaymentReferenceNumbersSelectedItem: referenceNumbers,
            clClientsSelectedItem: client ? [client] : [],
            clPoliciesSelectedItem: [],
            clSubpoliciesSelectedItem: [],
            clServiceSegmentsSelectedItem: [],
            clIsBrokerPremiumCollection: isGrEcoPayment ? true : null,
            paymentPlanRequest: null,
          });
          if (!isGrEcoPayment) {
            populateClearanceData({
              bankStatementEntryId: entry["bankStatementEntryId"],
              taxonomy,
              fromDate: clFromDateFilter,
              toDate: clToDateFilter,
              serviceSegmentCodes: [],
              policyNumbers: [],
              policySubnumbers: [],
              insurerIds: [],
              clientIds: [entry["clientInfo"]["id"]],
              payerIds: [],
              isBrokerPremiumCollection: null,
              paymentReferenceNumbers: referenceNumber ? [referenceNumber] : [],
              t,
            });
          }
        } else {
          setBankStatementsStoreValues({
            clearToSaldoBankStatementEntryDialog: {
              isOpen: true,
              bankStatementEntryId: entry["bankStatementEntryId"],
              bankStatementId: entry["bankStatementId"],
            },
          });
        }
      },
      infoFn: (rowId) => {
        setBankStatementsStoreValues({
          infoBankStatementEntryDialog: {
            isOpen: true,
            bankStatementEntryId: entry["bankStatementEntryId"],
          },
          infoBankStatementEntrySort: null,
        });
        populateBankStatementEntryInfoData({
          bankStatementEntryId: entry["bankStatementEntryId"],
        });
      },
      resolvementSuggestFn: (rowId) => {
        resolvementSuggest(
          {
            bankStatementEntryId: entry["bankStatementEntryId"],
            bankStatementId: entry["bankStatementId"],
          },
          (val) => {
            let newEntries = [];
            let newEntriesMetadataMap = { ...bankStatementEntryMetadataMap };
            for (let index = 0; index < bankStatementEntries.length; index++) {
              const bankStatementEntry = bankStatementEntries[index];

              if (
                bankStatementEntry.bankStatementEntryId ===
                  val.bankStatementEntryId &&
                !bankStatementEntry.clientId &&
                val?.clientInfo
              ) {
                let meta =
                  newEntriesMetadataMap[
                    bankStatementEntry.bankStatementEntryId
                  ];

                let changes = meta.changes ? [...meta.changes] : [];
                if (val.clientInfo) {
                  changes.push({
                    rowId: index,
                    columnId: "clientInfo",
                    newCell: {
                      isDisabled: false,
                      selectedValue: val.clientInfo,
                      t: t,
                      text:
                        val.clientInfo.name +
                        " (" +
                        val.clientInfo.internalNumber +
                        ")",
                      type: "client",
                      value: val.clientInfo,
                    },
                    previousCell: {
                      isDisabled: false,
                      selectedValue: null,
                      t: t,
                      text: "",
                      type: "client",
                      value: null,
                    },
                    type: "client",
                  });
                }
                newEntries.push({
                  ...bankStatementEntry,
                  clientInfo: val.clientInfo,
                });
                newEntriesMetadataMap[bankStatementEntry.bankStatementEntryId] =
                  {
                    ...meta,
                    inEditMode: true,
                    changes: changes,
                    oldRow: bankStatementEntry,
                  };
              } else {
                newEntries.push(bankStatementEntry);
              }
            }
            setBankStatementsStoreValues({
              bankStatementEntries: newEntries,
              bankStatementEntryMetadataMap: newEntriesMetadataMap,
            });
          }
        );
      },
      edited: rowsMetadataMap[entry["bankStatementEntryId"]]["inEditMode"],
      selected: rowsMetadataMap[entry["bankStatementEntryId"]]["selected"],
      isCleared: entry["isCleared"],
      isInsurerPayment: entry["isInsurerPayment"],
      t,
    };
  };
}

export default BankStatementEntryOptionsCellTypeHandler;
