import { defaultDatePickerStrings, useTheme } from "@fluentui/react";
import { Button, Radio, RadioGroup, Spinner } from "@fluentui/react-components";
import { DatePicker, IDatePicker } from "@fluentui/react-datepicker-compat";
import { SearchRegular } from "@fluentui/react-icons";

import moment from "moment";
import { makeStyles } from "office-ui-fabric-react";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  useSearchClientsSelectedItem,
  useSearchTerm,
} from "store/RemindersStore";
import { formatNumber } from "utils/number";
import { isInvalidDate } from "utils/utils";
import CreateReminderEntriesGrid from "./CreateReminderEntriesGrid";
import SearchCurrencyCodes from "./SearchCurrencyCodes";
import SearchInsurers from "./SearchInsurers";
import SearchPolicies from "./SearchPolicies";
import SearchServiceSegments from "./SearchServiceSegments";
import SearchSubpolicies from "./SearchSubpolicies";
import {
  useCreateReminderStatus,
  usePopulateCreateReminderDataStatus,
  useClFromDateFilter,
  useClToDateFilter,
  useClTotal,
  useClPoliciesSelectedItem,
  useClSubpoliciesSelectedItem,
  useClServiceSegmentsSelectedItem,
  useClCurrencyCodesSelectedItem,
  useClGrecoCompanyNumbers,
  useClInsurersSelectedItem,
  useClIsBrokerPremiumCollection,
  useRemindersCreateDialogStoreActions,
  useClIncludeIfAlreadyInReminder,
  useClPayersSelectedItem,
} from "store/RemindersCreateDialogStore";
import SearchInsurerCountry from "./SearchInsurerCountry";
import { VSpace } from "components/Spacer";
import { useAppStore } from "store/AppStore";
import SearchPayers from "./SearchPayers";

const onFormatDate = (date?: Date): string => {
  return !date
    ? ""
    : date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear();
};
type Props = {};
const ClearanceMenubar = (props: Props) => {
  const { setRemindersCreateDialogStoreValue, populateCreateReminderData } =
    useRemindersCreateDialogStoreActions();
  const [{ searchClientsSelectedItem }] = useSearchClientsSelectedItem();

  const [{ searchTerm }] = useSearchTerm();

  const [{ clFromDateFilter }] = useClFromDateFilter();

  const [{ clToDateFilter }] = useClToDateFilter();

  const [{ clTotal }] = useClTotal();

  const [{ clPoliciesSelectedItem }] = useClPoliciesSelectedItem();

  const [{ clSubpoliciesSelectedItem }] = useClSubpoliciesSelectedItem();

  const [{ clServiceSegmentsSelectedItem }] =
    useClServiceSegmentsSelectedItem();

  const [{ clCurrencyCodesSelectedItem }] = useClCurrencyCodesSelectedItem();

  const [{ clGrecoCompanyNumbers }] = useClGrecoCompanyNumbers();

  const [{ clInsurersSelectedItem }] = useClInsurersSelectedItem();

  const [{ clPayersSelectedItem }] = useClPayersSelectedItem();

  const [{ clIsBrokerPremiumCollection }] = useClIsBrokerPremiumCollection();

  const [{ clIncludeIfAlreadyInReminder }] = useClIncludeIfAlreadyInReminder();

  const [{ createReminderStatus }] = useCreateReminderStatus();

  const [{ populateCreateReminderDataStatus }] =
    usePopulateCreateReminderDataStatus();

  const loading =
    createReminderStatus === "loading" ||
    populateCreateReminderDataStatus === "loading";

  const { t } = useTranslation();
  const theme = useTheme();

  const fromDatePickerRef = React.useRef<IDatePicker>(null);
  const toDatePickerRef = React.useRef<IDatePicker>(null);
  const [{ taxonomy }] = useAppStore();

  const onParseFromDateFromString = React.useCallback(
    (newValue: string): Date => {
      const previousValue = clFromDateFilter || new Date();
      const newValueParts = (newValue || "").trim().split(".");
      const day =
        newValueParts.length > 0
          ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10)))
          : previousValue.getDate();
      const month =
        newValueParts.length > 1
          ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
          : previousValue.getMonth();
      let year =
        newValueParts.length > 2
          ? parseInt(newValueParts[2], 10)
          : previousValue.getFullYear();
      // if (year < 100) {
      //   year +=
      //     previousValue.getFullYear() - (previousValue.getFullYear() % 100);
      // }
      return new Date(year, month, day);
    },
    [clFromDateFilter]
  );

  const onParseToDateFromString = React.useCallback(
    (newValue: string): Date => {
      const previousValue = clToDateFilter || new Date();
      const newValueParts = (newValue || "").trim().split(".");
      const day =
        newValueParts.length > 0
          ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10)))
          : previousValue.getDate();
      const month =
        newValueParts.length > 1
          ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
          : previousValue.getMonth();
      let year =
        newValueParts.length > 2
          ? parseInt(newValueParts[2], 10)
          : previousValue.getFullYear();

      return new Date(year, month, day);
    },
    [clToDateFilter]
  );

  const useStyles = makeStyles({
    control: {
      maxWidth: "250px",
    },
    clDialogContainer: {
      display: "flex",
      height: "calc(100vh - 150px)",
      width: "calc(100vw - 350px)",
    },

    clDialogSidebar: {
      flex: "0 0 400px",
      backgroundColor: theme.palette.neutralLighter,
      padding: "20px",
      boxShadow: "2px 0 5px rgba(0,0,0,0.1)",
    },

    clDialogItem: {
      marginBottom: "10px",
      padding: "10px",
      backgroundColor: theme.palette.neutralLight,
      borderRadius: "4px",
      transition: "background-color 0.3s",
    },

    "clDialogItem:hover": {
      backgroundColor: "#d4d4d4",
    },

    clDialogContent: {
      flex: 1,
      padding: "0px 0px 0px 20px",
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },

    clDialogSection: {
      padding: "20px",
      backgroundColor: theme.palette.neutralLighter,
      borderRadius: "4px",
    },
  });
  const styles = useStyles();

  return (
    <>
      <div className={styles.clDialogContainer}>
        <div className={styles.clDialogSidebar}>
          <div className={styles.clDialogItem}>
            <div style={{ display: "flex" }}>
              <div style={{ flexGrow: 1 }}>
                <DatePicker
                  className={styles.control}
                  onError={(e) => {
                    return;
                  }}
                  onErrorCapture={(e) => {
                    return;
                  }}
                  componentRef={fromDatePickerRef}
                  allowTextInput
                  value={clFromDateFilter}
                  onSelectDate={(date: Date | undefined) => {
                    if (
                      moment(date?.getTime()).format("YYYY-MM-DD") !==
                      moment(clFromDateFilter?.getTime()).format("YYYY-MM-DD")
                    ) {
                      setRemindersCreateDialogStoreValue(
                        "clFromDateFilter",
                        date
                      );
                    } else {
                      setRemindersCreateDialogStoreValue(
                        "clFromDateFilter",
                        null
                      );
                    }
                  }}
                  parseDateFromString={(dateStr) =>
                    onParseFromDateFromString(dateStr)
                  }
                  strings={defaultDatePickerStrings}
                  disableAutoFocus={true}
                  formatDate={onFormatDate} // minDate={minDate}
                  disabled={loading}
                  onBlur={(e) => {
                    const inputEl: any =
                      e.currentTarget.childNodes[0].childNodes[0].childNodes[0]
                        .childNodes[0];
                    setTimeout(() => {
                      const insurersSearchInput = document.getElementById(
                        "insurersSearchInput"
                      );
                      insurersSearchInput.focus();
                      insurersSearchInput.blur();
                      const val = onParseFromDateFromString(inputEl.value);
                      if (
                        !isInvalidDate(val) &&
                        moment(val.getTime()).format("YYYY-MM-DD") !==
                          moment(clFromDateFilter?.getTime()).format(
                            "YYYY-MM-DD"
                          )
                      )
                        setRemindersCreateDialogStoreValue(
                          "clFromDateFilter",
                          isNaN(val.getTime()) ? null : moment(val).toDate()
                        );
                    }, 300);
                  }}
                  placeholder="Select from date..."
                />
              </div>
              <div style={{ flexGrow: 1 }}>
                <DatePicker
                  onError={(e) => {
                    return;
                  }}
                  onErrorCapture={(e) => {
                    return;
                  }}
                  componentRef={toDatePickerRef}
                  allowTextInput
                  value={clToDateFilter}
                  onSelectDate={(date: Date | undefined) => {
                    if (
                      moment(date?.getTime()).format("YYYY-MM-DD") !==
                      moment(clToDateFilter?.getTime()).format("YYYY-MM-DD")
                    ) {
                      setRemindersCreateDialogStoreValue(
                        "clToDateFilter",
                        date
                      );
                    } else {
                      setRemindersCreateDialogStoreValue(
                        "clToDateFilter",
                        null
                      );
                    }
                  }}
                  parseDateFromString={onParseToDateFromString}
                  strings={defaultDatePickerStrings}
                  disableAutoFocus={true}
                  formatDate={onFormatDate}
                  onBlur={(e) => {
                    const inputEl: any =
                      e.currentTarget.childNodes[0].childNodes[0].childNodes[0]
                        .childNodes[0];
                    setTimeout(() => {
                      const insurersSearchInput = document.getElementById(
                        "insurersSearchInput"
                      );
                      insurersSearchInput.focus();
                      insurersSearchInput.blur();
                      const val = onParseFromDateFromString(inputEl.value);
                      if (
                        !isInvalidDate(val) &&
                        moment(val.getTime()).format("YYYY-MM-DD") !==
                          moment(clToDateFilter?.getTime()).format("YYYY-MM-DD")
                      )
                        setRemindersCreateDialogStoreValue(
                          "clToDateFilter",
                          isNaN(val.getTime()) ? null : moment(val).toDate()
                        );
                    }, 300);
                  }}
                  disabled={loading}
                  placeholder="Select to date..."
                />
              </div>
            </div>
          </div>
          <div className={styles.clDialogItem}>
            <SearchInsurerCountry />
            <VSpace height={10} />
            <SearchInsurers />
            {clInsurersSelectedItem.length === 0 ? (
              <span style={{ color: "crimson" }}>
                {t("pct.error.InsurerEmpty.txt")}
              </span>
            ) : null}
          </div>
          <div className={styles.clDialogItem}>
            <SearchPayers />
          </div>
          <div className={styles.clDialogItem}>
            <SearchCurrencyCodes />
          </div>
          <div className={styles.clDialogItem}>
            <SearchServiceSegments />
          </div>
          <div className={styles.clDialogItem}>
            <SearchPolicies />
          </div>
          <div className={styles.clDialogItem}>
            <SearchSubpolicies />
          </div>

          <div className={styles.clDialogItem}>
            {t("pct.isBrokerPremiumCollection.label")}:{" "}
            <RadioGroup
              disabled={loading ? true : false}
              layout="horizontal"
              onChange={(e, data) =>
                setRemindersCreateDialogStoreValue(
                  "clIsBrokerPremiumCollection",
                  data.value === "true" ? true : false
                )
              }
              value={clIsBrokerPremiumCollection + ""}
            >
              <Radio value="true" label={t("greco.yes")} />
              <Radio value="false" label={t("greco.no")} />
            </RadioGroup>
          </div>
          <div className={styles.clDialogItem}>
            {t("pct.includeIfAlreadyInReminder.label")}:{" "}
            <RadioGroup
              disabled={loading ? true : false}
              layout="horizontal"
              onChange={(e, data) =>
                setRemindersCreateDialogStoreValue(
                  "clIncludeIfAlreadyInReminder",
                  data.value === "true" ? true : false
                )
              }
              value={clIncludeIfAlreadyInReminder + ""}
            >
              <Radio value="true" label={t("greco.yes")} />
              <Radio value="false" label={t("greco.no")} />
            </RadioGroup>
          </div>
          <div style={{ textAlign: "right", marginTop: "30px" }}>
            <Button
              appearance="primary"
              icon={<SearchRegular />}
              onClick={(e) => {
                populateCreateReminderData({
                  t,
                  taxonomy,
                  searchTerm: searchTerm,
                  clientId: searchClientsSelectedItem?.value?.id,
                  insurerIds: clInsurersSelectedItem
                    ? clInsurersSelectedItem.map((i) => i.value)
                    : null,
                  payerIds: clPayersSelectedItem
                    ? clPayersSelectedItem.map((i) => i.value)
                    : null,
                  serviceSegmentCodes: clServiceSegmentsSelectedItem
                    ? clServiceSegmentsSelectedItem.map((i) => i.value)
                    : null,
                  policyNumbers: clPoliciesSelectedItem
                    ? clPoliciesSelectedItem.map((i) => i.value)
                    : null,
                  policySubnumbers: clSubpoliciesSelectedItem
                    ? clSubpoliciesSelectedItem.map((i) => i.value)
                    : null,
                  from: clFromDateFilter,
                  to: clToDateFilter,
                  isBrokerPremiumCollection: clIsBrokerPremiumCollection,
                  includeIfAlreadyInReminder: clIncludeIfAlreadyInReminder,
                  currencyCode: clCurrencyCodesSelectedItem.value,
                  maxResults: 5000,
                  grecoCompanyNumbers: clGrecoCompanyNumbers,
                });
              }}
              disabled={
                loading ||
                clInsurersSelectedItem === null ||
                clInsurersSelectedItem.length === 0
              }
            >
              {t("pct.search.label")}
            </Button>
          </div>
        </div>
        <div className={styles.clDialogContent}>
          <div className={styles.clDialogSection}>
            {!loading ? (
              <>
                <CreateReminderEntriesGrid />
              </>
            ) : (
              <div
                style={{
                  width: "calc(100vw - 670px)",
                  height: "calc(100vh - 200px)",
                  paddingTop: "calc(100vh - 600px)",
                }}
              >
                <Spinner label={t("greco.loading")} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ClearanceMenubar;
