import moment from "moment";
import {
  Reminder,
  ReminderEntry,
  ReminderEntryInfo,
  ReminderEntryMetadataMap,
  ReminderInfo,
  ReminderMetadataMap,
  UnsettledPaymentPlanMetadataMap,
} from "utils/types";
import {
  deleteMissingProperties,
  getShortInsurerName,
  handleAxiosError,
  isInvalidDate,
  parseFloatBasedOnLocale,
  prepareNotificationObject,
} from "utils/utils";
import * as API from "../api/api";
import { StoreState } from "./RemindersStore";
import { StoreState as StoreCreateDialogState } from "./RemindersCreateDialogStore";
import i18next from "i18next";
import { toast } from "components/FluentToast";
import { setNotificationMessage } from "./AppStoreActions";

export const setStoreValue =
  (name: keyof StoreState, value) =>
  ({ setState }) => {
    setState({
      [name]: value,
    });
  };

export const setStoreValues =
  (values) =>
  ({ setState }) => {
    setState({
      ...values,
    });
  };

export const setStoreCreateDialogValue =
  (name: keyof StoreCreateDialogState, value) =>
  ({ setState }) => {
    setState({
      [name]: value,
    });
  };

export const setStoreCreateDialogValues =
  (values) =>
  ({ setState }) => {
    setState({
      ...values,
    });
  };

const formatNumber = (value: number) => {
  const locale = window.navigator.languages[0];

  const groupingFormat = new Intl.NumberFormat(locale, {
    useGrouping: true,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
  return Number.isNaN(value) ? "" : groupingFormat.format(value);
};

export const loadReminders =
  (payload, onSuccess?) =>
  async ({ setState, getState, dispatch }) => {
    try {
      const { updatedGrecoCompanies } = payload;
      if (getState().remindersLoadStatus === "loading") return;
      setState({
        remindersLoadStatus: "loading",
      });
      let query: any = {
        From: getState().fromDateFilter
          ? moment(getState().fromDateFilter).format("YYYY-MM-DD")
          : null,
        To: getState().toDateFilter
          ? moment(getState().toDateFilter).format("YYYY-MM-DD")
          : null,
        GrecoCompanyNumbers: updatedGrecoCompanies
          .filter((el) => el.selected)
          .map((el) => el.companyNumber),
        PolicyNumbers:
          getState().searchPolicyNumbers !== null &&
          getState().searchPolicyNumbers !== ""
            ? [getState().searchPolicyNumbers]
            : null,
        PolicySubNumbers:
          getState().searchPolicySubNumbers !== null &&
          getState().searchPolicySubNumbers !== ""
            ? [getState().searchPolicySubNumbers]
            : null,
      };
      if (getState().searchClientsSelectedItem) {
        query = {
          ...query,
          ClientIds: [getState().searchClientsSelectedItem.value?.id],
        };
      }

      const response = await API.reminderQuery(query);
      let reminders = response.data.filter((el) => el.isDeleted === false);
      let reminderEntries = [];
      let reminderMetadataMap: ReminderMetadataMap = {};
      let reminderEntryMetadataMap: ReminderEntryMetadataMap = {};

      reminders = reminders.map((el) => {
        return {
          ...el,
          clientName:
            (el.clientInfo.isCompany ? "" : " ") +
            el.clientInfo.name +
            " (" +
            el.clientInfo.internalNumber +
            ")",
          payerName:
            (el.payerClientInfo.isCompany ? "" : " ") +
            el.payerClientInfo.name +
            " (" +
            el.payerClientInfo.internalNumber +
            ")",
        };
      });
      reminders.forEach((statement) => {
        reminderMetadataMap[statement.reminderId] = {
          selected: false,
          inEditMode: false,
          changes: [],
          deleted: statement.isDeleted,
          editable: !statement.isDeleted && !statement.reminderDocumentPath,
          visible: true,
          oldRow: null,
        };
        statement.reminderEntries
          .filter((el) => el.isDeleted === false)
          .forEach((entry) => {
            reminderEntries.push(entry);
            reminderEntryMetadataMap[entry.reminderEntryId] = {
              inEditMode: false,
              changes: [],
              deleted: entry.isDeleted,
              editable:
                !entry.isDeleted &&
                !statement.reminderDocumentPath &&
                !statement.isDeleted &&
                !entry.isCleared,
              visible: false,
              oldRow: null,
              resolved: entry.clientId !== null,
              cleared: entry.isCleared,
              selected: true,
            };
          });
        delete statement.reminderEntries;
      });

      setState({
        reminders: reminders.map((el) => {
          return {
            ...el,
            insurerName: el.insurerInfos
              .map((el) => {
                return el.name + " (" + el.internalNumber + ")";
              })
              .join(", "),
            lastPaymentAt:
              el.lastModifiedAt === el.createdAt ? null : el.lastModifiedAt,
            booked_paid_amount:
              formatNumber(el.totalPaidBookedAmount) +
              " / " +
              formatNumber(el.totalBookedAmount),
            total_paid_amount:
              formatNumber(el.totalPaidAmount) +
              " / " +
              formatNumber(el.totalAmount),
            discount_paid_amount:
              formatNumber(el.totalPaidDiscountAmount) +
              " / " +
              formatNumber(el.totalDiscountAmount),

            premium_paid_amount_status:
              Number(el.totalPaidAmount.toFixed(2)) >=
              Number(el.totalAmount.toFixed(2))
                ? "green"
                : Number(el.totalPaidAmount.toFixed(2)) > 0
                ? "orange"
                : "red",
            // Create indicator for totalDiscountAmount. If it is zero, then make it red, if it is partially paid make it orange, and if it is
            // fully paid (totalPaidDiscountAmount === totalDiscountAmount) make it green.
            discount_paid_amount_status:
              Number(el.totalDiscountAmount.toFixed(2)) === 0
                ? "gray"
                : Number(el.totalPaidDiscountAmount.toFixed(2)) >=
                  Number(el.totalDiscountAmount.toFixed(2))
                ? "green"
                : Number(el.totalPaidDiscountAmount.toFixed(2)) > 0
                ? "orange"
                : "red",
          };
        }),
        reminderEntries,
        reminderMetadataMap,
        reminderEntryMetadataMap,
        remindersLoadStatus: "success",
      });
      // onSuccess && onSuccess(response.headers["usertype"]);
    } catch (error: any) {
      if (error.code !== "ERR_CANCELED") {
        handleAxiosError(error, (message) => {
          dispatch(
            setNotificationMessage(
              prepareNotificationObject("loadReminders", message)
            )
          );
        });
        setState({
          remindersLoadStatus: "error",
        });
      }
    }
  };

export const filterReminders =
  (payload) =>
  ({ setState, getState, dispatch }) => {
    const { searchTerm } = payload;
    let reminders = getState().reminders as Reminder[];
    let reminderEntries = getState().reminderEntries as ReminderEntry[];
    let reminderMetadataMap = getState()
      .reminderMetadataMap as ReminderMetadataMap;
    let reminderEntryMetadataMap = getState()
      .reminderEntryMetadataMap as ReminderEntryMetadataMap;
    reminders.forEach((el: any) => {
      let isMatch = true;
      if (el.reminderId < 0) {
        reminderMetadataMap[el.reminderId].visible = true;
      } else {
        if (searchTerm && searchTerm !== "") {
          isMatch =
            isMatch &&
            (el.reminderNumber
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
              el.clientName
                .toString()
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              el.insurerName
                .toString()
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) |
                el.reminderComment
                  .toString()
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase()) ||
              (el.createdAt
                ? moment(el.createdAt).format("DD.MM.YYYY")
                : ""
              ).includes(searchTerm.toLowerCase()) ||
              (el.lastPaymentAt
                ? moment(el.lastPaymentAt).format("DD.MM.YYYY")
                : ""
              ).includes(searchTerm.toLowerCase()) ||
              (el.dueDate
                ? moment(el.dueDate).format("DD.MM.YYYY")
                : ""
              ).includes(searchTerm.toLowerCase()));
        }
      }
      reminderMetadataMap[el.reminderId].visible = isMatch;
      reminderEntries
        .filter((entry) => entry.reminderId === el.reminderId)
        .forEach((entry) => {
          reminderEntryMetadataMap[entry.reminderEntryId].visible = isMatch;
        });
      if (!isMatch) {
        reminderMetadataMap[el.reminderId].selected = false;
      }
    });
    setState({
      reminderMetadataMap: reminderMetadataMap,
    });
  };

export const addNewEmptyReminder =
  () =>
  ({ setState, getState, dispatch }) => {
    let reminders = getState().reminders as Reminder[];
    const reminderMetadataMap = getState()
      .reminderMetadataMap as ReminderMetadataMap;
    const reminderId = -Math.random();

    reminders = [
      {
        ...new Reminder(),
        reminderId: reminderId,
        createdAt: moment().utc().toISOString(),
        reminderEntries: [],
      },
      ...reminders,
    ];
    reminderMetadataMap[reminderId] = {
      selected: true,
      inEditMode: true,
      changes: [],
      deleted: false,
      editable: true,
      visible: true,
      oldRow: null,
    };
    setState({
      reminders: reminders,
      reminderMetadataMap: reminderMetadataMap,
    });
  };

export const addNewEmptyReminderEntry =
  (reminderId) =>
  ({ setState, getState, dispatch }) => {
    let reminderEntries = getState().reminderEntries as ReminderEntry[];
    const reminderEntryId = -Math.random();
    const reminderEntryMetadataMap = getState()
      .reminderEntryMetadataMap as ReminderEntryMetadataMap;
    reminderEntryMetadataMap[reminderEntryId] = {
      inEditMode: true,
      changes: [],
      deleted: false,
      editable: true,
      visible: true,
      oldRow: null,
      resolved: false,
      cleared: false,
      selected: true,
    };
    reminderEntries = [
      {
        ...new ReminderEntry(),
        reminderId: reminderId,
        reminderEntryId: reminderEntryId,
        bookingDate: moment().utc().toISOString(),
      },
      ...reminderEntries,
    ];

    setState({
      reminderEntries: reminderEntries,
      reminderEntryMetadataMap: reminderEntryMetadataMap,
    });
  };

export const cancelEditReminder =
  (rowId) =>
  ({ setState, getState, dispatch }) => {
    let reminders = getState().reminders as Reminder[];
    const reminderInfo = getState().reminderMetadataMap[rowId] as ReminderInfo;
    if (rowId < 0) {
      reminders = reminders.filter((el) => el.reminderId !== rowId);
    } else {
      const oldReminder = reminderInfo.oldRow;
      reminders = reminders.map((el) => {
        if (el.reminderId === rowId) {
          return {
            ...el,
            ...oldReminder,
          };
        }
        return el;
      });
    }

    setState({
      reminders: reminders,
      reminderMetadataMap: {
        ...getState().reminderMetadataMap,
        [rowId]: {
          ...reminderInfo,
          inEditMode: false,
          changes: [],
        },
      },
    });
  };

export const cancelEditReminderEntry =
  (rowId) =>
  ({ setState, getState, dispatch }) => {
    let reminderEntries = getState().reminderEntries as ReminderEntry[];
    const reminderEntryInfo = getState().reminderEntryMetadataMap[
      rowId
    ] as ReminderEntryInfo;
    if (rowId < 0) {
      reminderEntries = reminderEntries.filter(
        (el) => el.reminderEntryId !== rowId
      );
    } else {
      const reminderEntry = reminderEntryInfo.oldRow;
      reminderEntries = reminderEntries.map((el) => {
        if (el.reminderEntryId === rowId) {
          return {
            ...deleteMissingProperties(el, reminderEntry),
            ...reminderEntry,
          };
        }
        return el;
      });
    }

    setState({
      reminderEntries: reminderEntries,
      reminderEntryMetadataMap: {
        ...getState().reminderEntryMetadataMap,
        [rowId]: {
          ...reminderEntryInfo,
          inEditMode: false,
          changes: [],
        },
      },
    });
  };

export const deleteReminder =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState, dispatch }) => {
    if (getState().deleteReminderStatus === "loading") return;
    try {
      setState({
        deleteReminderStatus: "loading",
      });
      const res = await API.deleteReminder(payload);
      setState({
        deleteReminderStatus: "success",
      });
      onSuccess && onSuccess(res.data);
    } catch (error) {
      handleAxiosError(error, (message) => {
        dispatch(
          setNotificationMessage(
            prepareNotificationObject("deleteReminder", message)
          )
        );
      });
      setState({
        deleteReminderStatus: "error",
      });
      onError && onError(error);
    }
  };

export const getInsurersByCountry =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState, dispatch }) => {
    if (getState().getInsurersByCountryStatus === "loading") return;
    try {
      setState({
        getInsurersByCountryStatus: "loading",
      });
      const res = await API.getInsurersByCountry(payload);
      setState({
        getInsurersByCountryStatus: "success",
      });
      onSuccess && onSuccess(res.data);
    } catch (error) {
      handleAxiosError(error, (message) => {
        dispatch(
          setNotificationMessage(
            prepareNotificationObject("getInsurersByCountry", message)
          )
        );
      });
      setState({
        getInsurersByCountryStatus: "error",
      });
      onError && onError(error);
    }
  };

export const createReminder =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState, dispatch }) => {
    const {
      clientId,

      reminder,
    } = payload;
    setState({
      createReminderStatus: "loading",
    });
    try {
      const res = await API.createReminder(clientId, reminder);
      setState({
        createReminderStatus: "success",
      });
      onSuccess && onSuccess(res.data);
    } catch (error) {
      handleAxiosError(error, (message) => {
        dispatch(
          setNotificationMessage(
            prepareNotificationObject("createReminder", message)
          )
        );
      });
      setState({
        createReminderStatus: "error",
      });
      onError && onError(error);
    }
  };

export const getReminder =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState, dispatch }) => {
    const {
      clientId,

      reminderId,
    } = payload;
    setState({
      createReminderStatus: "loading",
    });
    try {
      const res = await API.getReminder(clientId, reminderId);
      setState({
        createReminderStatus: "success",
      });
      onSuccess &&
        onSuccess(
          res.data.reminderEntries.map((el) => {
            return {
              ...el,
              insurerNameShort:
                getShortInsurerName(el.insurerId + "") === ""
                  ? el.insurerName
                  : getShortInsurerName(el.insurerId + "") +
                    " (" +
                    el.paymentPlanEntry?.insurerInternalNumber +
                    ")",
            };
          })
        );
    } catch (error) {
      handleAxiosError(error, (message: any) => {
        // Explicitly specify the type of the 'message' parameter
        dispatch(
          setNotificationMessage(
            prepareNotificationObject("getReminder", message) as Notification
          )
        );
      });
      setState({
        createReminderStatus: "error",
      });
      onError && onError(error);
    }
  };

export const populateCreateReminderData =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState, dispatch }) => {
    const {
      searchTerm,
      clientId,
      insurerIds,
      payerIds,
      serviceSegmentCodes,
      from,
      to,
      isBrokerPremiumCollection,
      includeIfAlreadyInReminder,
      currencyCode,
      maxResults,
      policyNumbers,
      policySubnumbers,
      grecoCompanyNumbers,
      t,
      taxonomy,
    } = payload;
    setState({
      populateCreateReminderDataStatus: "loading",
    });
    try {
      const res = await API.suggestReminder({
        searchTerm,
        clientId,
        insurerIds,
        payerIds,
        serviceSegmentCodes,
        from:
          from === null || isInvalidDate(from)
            ? null
            : moment(from).format("YYYY-MM-DD"),
        to:
          to === null || isInvalidDate(to)
            ? null
            : moment(to).format("YYYY-MM-DD"),
        isBrokerPremiumCollection,
        includeIfAlreadyInReminder,
        currencyCode,
        maxResults,
        policyNumbers,
        policySubnumbers,
        grecoCompanyNumbers,
      });
      let suggestedReminder = res.data;
      const suggestedUnsettledPaymentPlans = (
        suggestedReminder.suggestedPayments || []
      ).map((el) => {
        return {
          ...el,
          insuranceLineName: t(
            taxonomy?.InsuranceLine?.byId[el["insuranceLineCode"]].code
          ),
        };
      });
      const clUnsettledPaymentPlanMetadataMap = {};
      suggestedUnsettledPaymentPlans.forEach((el) => {
        // filter list by search term
        let itemVisible = true;
        // if (searchTerm) {
        //   if (
        //     !(
        //       el.policyName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        //       el.insurerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        //       el.policyNumber
        //         .toLowerCase()
        //         .includes(searchTerm.toLowerCase()) ||
        //       el.policySubNumber
        //         .toLowerCase()
        //         .includes(searchTerm.toLowerCase())
        //     )
        //   )
        //     itemVisible = false;
        // }

        clUnsettledPaymentPlanMetadataMap[el.paymentPlanEntryId] = {
          visible: itemVisible,
          editable: false,
          selected:
            suggestedUnsettledPaymentPlans.find(
              (el2) => el.paymentPlanEntryId === el2.paymentPlanEntryId
            ) !== undefined,
          changes: [],
        };
      });
      setState({
        clUnsettledPaymentPlanSelectAll: true,
        suggestedReminder: suggestedReminder,
        clUnsettledPaymentPlans: suggestedUnsettledPaymentPlans,
        clUnsettledPaymentPlanMetadataMap: clUnsettledPaymentPlanMetadataMap,
        clReminderNumber: suggestedReminder.reminderNumber,
        populateCreateReminderDataStatus: "success",
      });
    } catch (error) {
      handleAxiosError(error, (message) => {
        dispatch(
          setNotificationMessage(
            prepareNotificationObject("populateCreateReminderData", message)
          )
        );
      });
      setState({
        populateCreateReminderDataStatus: "error",
        createReminderDialog: {
          isOpen: false,
          reminderId: null,
        },
      });
      onError && onError(error);
    }
  };

export const deleteReminderEntry =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState, dispatch }) => {
    if (getState().deleteReminderEntryStatus === "loading") return;
    try {
      setState({
        deleteReminderEntryStatus: "loading",
      });
      const res = await API.deleteReminderEntry(payload);
      const reminderEntryMetadataMap = {
        ...(getState().reminderEntryMetadataMap as ReminderEntryMetadataMap),
      };

      reminderEntryMetadataMap[payload.reminderEntryId].visible = false;
      reminderEntryMetadataMap[payload.reminderEntryId].deleted = true;
      setState({
        reminderEntryMetadataMap: reminderEntryMetadataMap,
        deleteReminderEntryStatus: "success",
      });
      onSuccess && onSuccess(res.data);
    } catch (error) {
      handleAxiosError(error, (message) => {
        dispatch(
          setNotificationMessage(
            prepareNotificationObject("deleteReminderEntry", message)
          )
        );
      });
      setState({
        deleteReminderEntryStatus: "error",
      });
      onError && onError(error);
    }
  };

export const saveReminder =
  (rowId: any, onSuccess?, onError?) =>
  async ({ setState, getState, dispatch }) => {
    // const reminder = getState().reminders.find(
    //   (el) => el.reminderId === rowId
    // ) as Reminder;
    // const reminderMetadataMap = getState()
    //   .reminderMetadataMap as ReminderMetadataMap;
    // if (rowId < 0) {
    //   if (getState().reminderSaveStatus === "loading") return;
    //   try {
    //     setState({
    //       reminderSaveStatus: "loading",
    //     });
    //     const res = await API.createReminder(reminder);
    //     const newReminder = res.data;
    //     reminderMetadataMap[newReminder.reminderId] = {
    //       ...reminderMetadataMap[rowId],
    //       selected: true,
    //       inEditMode: false,
    //       changes: [],
    //     };
    //     delete reminderMetadataMap[rowId];
    //     setState({
    //       reminders: [
    //         ...getState().reminders.map((el) =>
    //           el.reminderId === rowId ? newReminder : el
    //         ),
    //       ],
    //       reminderMetadataMap: reminderMetadataMap,
    //       reminderSaveStatus: "success",
    //     });
    //     onSuccess && onSuccess(newReminder);
    //     // toast.success(i18next.t("greco.success"));
    //   } catch (err) {
    //     handleAxiosError(err, (message) => { dispatch(setNotificationMessage(prepareNotificationObject(new Error().stack?.split("\n")[1].trim().split(" ")[1], message)));});
    //     setState({
    //       reminderSaveStatus: "error",
    //     });
    //     onError && onError(err);
    //   }
    // } else {
    //   if (getState().reminderSaveStatus === "loading") return;
    //   try {
    //     setState({
    //       reminderSaveStatus: "loading",
    //     });
    //     const res = await API.updateReminder({
    //       reminder: reminder,
    //       reminderId: reminder.reminderId,
    //     });
    //     const updatedReminder = res.data;
    //     reminderMetadataMap[updatedReminder.reminderId] = {
    //       ...reminderMetadataMap[rowId],
    //       selected: true,
    //       inEditMode: false,
    //       changes: [],
    //     };
    //     setState({
    //       reminders: [
    //         ...getState().reminders.map((el) =>
    //           el.reminderId === updatedReminder.reminderId
    //             ? updatedReminder
    //             : el
    //         ),
    //       ],
    //       reminderMetadataMap: reminderMetadataMap,
    //       reminderSaveStatus: "success",
    //     });
    //     // toast.success(i18next.t("greco.success"));
    //     onSuccess && onSuccess(updatedReminder);
    //   } catch (err) {
    //     handleAxiosError(err, (message) => { dispatch(setNotificationMessage(prepareNotificationObject(new Error().stack?.split("\n")[1].trim().split(" ")[1], message)));});
    //     setState({
    //       reminderSaveStatus: "error",
    //     });
    //     onError && onError(err);
    //   }
    // }
  };

export const saveReminderEntry =
  (rowId: any, onSuccess?, onError?) =>
  async ({ setState, getState, dispatch }) => {
    // const reminderEntry = getState().reminderEntries.find(
    //   (el) => el.reminderEntryId === rowId
    // ) as ReminderEntry;
    // if (rowId < 0) {
    //   if (getState().reminderEntrySaveStatus === "loading") return;
    //   try {
    //     setState({
    //       reminderEntrySaveStatus: "loading",
    //     });
    //     let entry = { ...reminderEntry }; //, ...reminderEntry.clientInfo };
    //     if (reminderEntry.clientInfo) {
    //       entry.clientId = reminderEntry.clientInfo.id;
    //     }
    //     delete entry.reminderEntryId;
    //     delete entry.clientInfo;
    //     entry.clientCode = entry.clientCode ? entry.clientCode + "" : "";
    //     const res = await API.createReminderEntry(
    //       reminderEntry.reminderId,
    //       entry
    //     );
    //     let newReminderEntry = {
    //       ...res.data,
    //     };
    //     if (reminderEntry.clientInfo) {
    //       newReminderEntry = {
    //         ...newReminderEntry,
    //         clientInfo: { ...reminderEntry.clientInfo },
    //       };
    //     }
    //     const reminderEntryMetadataMap = {
    //       ...(getState().reminderEntryMetadataMap as ReminderEntryMetadataMap),
    //     };
    //     reminderEntryMetadataMap[newReminderEntry.reminderEntryId] = {
    //       ...reminderEntryMetadataMap[rowId],
    //       inEditMode: false,
    //       changes: [],
    //     };
    //     delete reminderEntryMetadataMap[rowId];
    //     setState({
    //       reminderEntries: [
    //         ...getState().reminderEntries.map((el) =>
    //           el.reminderEntryId === rowId ? newReminderEntry : el
    //         ),
    //       ],
    //       reminderEntryMetadataMap: reminderEntryMetadataMap,
    //       reminderEntrySaveStatus: "success",
    //     });
    //     onSuccess && onSuccess(newReminderEntry);
    //     toast.success(i18next.t("greco.success"));
    //   } catch (err) {
    //     handleAxiosError(err, (message) => { dispatch(setNotificationMessage(prepareNotificationObject(new Error().stack?.split("\n")[1].trim().split(" ")[1], message)));});
    //     setState({
    //       reminderEntrySaveStatus: "error",
    //     });
    //     onError && onError(err);
    //   }
    // } else {
    //   if (getState().reminderEntrySaveStatus === "loading") return;
    //   try {
    //     setState({
    //       reminderEntrySaveStatus: "loading",
    //     });
    //     const reminderEntryMetadataMap = getState()
    //       .reminderEntryMetadataMap as ReminderEntryMetadataMap;
    //     const reminderEntryEdited = reminderEntryMetadataMap[rowId].inEditMode;
    //     const changes = reminderEntryMetadataMap[rowId].changes;
    //     const bankStetementEntryContainsClientInfo =
    //       "clientInfo" in reminderEntry;
    //     const reminderEntryContainsChangesToClientInfo =
    //       "changes" in reminderEntryMetadataMap[rowId] &&
    //       changes.find((el) => el.type === "client") !== undefined;
    //     let entry = null;
    //     if (
    //       reminderEntryEdited &&
    //       bankStetementEntryContainsClientInfo &&
    //       reminderEntryContainsChangesToClientInfo
    //     ) {
    //       const reminderId = reminderEntry.reminderId;
    //       const reminderEntryId = reminderEntry.reminderEntryId;
    //       const clientId = reminderEntry.clientInfo.id;
    //       const res = await API.resolvementManual({
    //         reminderId,
    //         reminderEntryId,
    //         clientId,
    //       });
    //       entry = { ...res.data }; //, ...reminderEntry.clientInfo };
    //     } else {
    //       entry = { ...reminderEntry }; //, ...reminderEntry.clientInfo };
    //       delete entry.clientInfo;
    //     }
    //     entry.clientCode = entry.clientCode + "";
    //     const reminderDocumentPath = getState().reminders.find(
    //       (el) => el.reminderId === reminderEntry.reminderId
    //     ).reminderDocumentPath;
    //     let updatedReminderEntry = null;
    //     if (!reminderDocumentPath) {
    //       const res = await API.updateReminderEntry({
    //         clientId: entry.clientId,
    //         reminderEntry: entry,
    //         reminderId: reminderEntry.reminderId,
    //         reminderEntryId: reminderEntry.reminderEntryId,
    //       });
    //       updatedReminderEntry = {
    //         ...res.data,
    //         clientInfo: res.data.clientInfo
    //           ? {
    //               id: res.data.clientInfo.id,
    //               name: res.data.clientInfo.name,
    //               internalNumber: res.data.clientInfo.internalNumber,
    //             }
    //           : null,
    //       };
    //     } else {
    //       updatedReminderEntry = {
    //         ...entry,
    //         clientInfo: entry.clientInfo
    //           ? {
    //               id: entry.clientInfo.id,
    //               name: entry.clientInfo.name,
    //               internalNumber: entry.clientInfo.internalNumber,
    //             }
    //           : null,
    //       };
    //     }
    //     reminderEntryMetadataMap[updatedReminderEntry.reminderEntryId] = {
    //       ...reminderEntryMetadataMap[rowId],
    //       inEditMode: false,
    //       changes: [],
    //     };
    //     setState({
    //       reminderEntries: [
    //         ...getState().reminderEntries.map((el) =>
    //           el.reminderEntryId === updatedReminderEntry.reminderEntryId
    //             ? updatedReminderEntry
    //             : el
    //         ),
    //       ],
    //       reminderEntryMetadataMap: reminderEntryMetadataMap,
    //       reminderEntrySaveStatus: "success",
    //     });
    //     //toast.success(i18next.t("greco.success"));
    //     onSuccess && onSuccess(updatedReminderEntry);
    //   } catch (err) {
    //     handleAxiosError(err, (message) => { dispatch(setNotificationMessage(prepareNotificationObject(new Error().stack?.split("\n")[1].trim().split(" ")[1], message)));});
    //     setState({
    //       reminderEntrySaveStatus: "error",
    //     });
    //     onError && onError(err);
    //   }
    // }
  };

export const resolvementSuggest =
  ({ reminderId, reminderEntryId }, onSuccess?, onError?) =>
  async ({ setState, getState, dispatch }) => {
    if (getState().resolvementLoadStatus === "loading") return;
    try {
      setState({
        resolvementLoadStatus: "loading",
      });
      const res = await API.resolvementSuggest({
        reminderId,
        reminderEntryId,
      });
      const retVal = res.data;
      setState({
        resolvementLoadStatus: "success",
      });
      if (retVal.clientInfo === null && retVal.clientId === null) {
        toast.error(i18next.t("pct.noresolvementavailable.label"));
      }

      onSuccess && onSuccess(retVal);
    } catch (error) {
      handleAxiosError(error, (message) => {
        dispatch(
          setNotificationMessage(
            prepareNotificationObject("resolvementSuggest", message)
          )
        );
      });
      setState({
        resolvementLoadStatus: "error",
      });
      onError && onError(error);
    }
  };

export const resolvementSuggestForStatement =
  ({ reminderId }, onSuccess?, onError?) =>
  async ({ setState, getState, dispatch }) => {
    if (getState().resolvementLoadStatus === "loading") return;
    try {
      setState({
        resolvementLoadStatus: "loading",
      });
      const res = await API.resolvementSuggestForStatement({
        reminderId,
      });
      const retVal = res.data;
      setState({
        resolvementLoadStatus: "success",
      });
      onSuccess && onSuccess(retVal);
    } catch (error) {
      handleAxiosError(error, (message) => {
        dispatch(
          setNotificationMessage(
            prepareNotificationObject("resolvementSuggestForStatement", message)
          )
        );
      });
      setState({
        resolvementLoadStatus: "error",
      });
      onError && onError(error);
    }
  };
