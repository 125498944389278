import { Setting } from "@greco/components/dist/types/types";
import {
  createStore,
  createHook,
  createActionsHook,
  createStateHook,
} from "react-sweet-state";
import { ExpandedColumn } from "types/columns";
import { LoadStatus, Notification } from "types/types";
import {
  createSetting,
  deleteSetting,
  loadSettings,
  loadClientBalances,
  setStoreValue,
  updateSetting,
  loadClientBalancePlansForReport,
  searchClients,
  searchInsurers,
  setStoreValues,
  filterClientBalances,
  generateExcel,
  populateClearanceData,
  getClientSaldo,
  clearClientSaldo,
  populateClientBalanceInfoData,
  getInsurersByCountry,
  // filterUnsettledPaymentPlans,
} from "./ClientBalancePageStoreActions";
import {
  ClientBalancesMetadataMap,
  SettledPaymentMetadataMap,
  UnsettledPaymentPlanMetadataMap,
} from "utils/types";
import moment from "moment";
import { setNotificationMessage } from "./AppStoreActions";

export type StoreState = {
  notifications: Notification[];

  clientBalance: any;
  clientBalances: any[];
  clientBalancesMetadataMap: ClientBalancesMetadataMap;

  clientBalancesLoadStatus: LoadStatus;

  searchClientsSelectedItem: any;
  searchCurrencyItem: any;
  searchTerm: string;
  showZeroBalanceClients: boolean;

  clearClientSaldoDialog: {
    isOpen: boolean;
    clientName: string;
  };
  clearClientSaldoStatus: LoadStatus;
  clPaymentDate: Date;
  clComment: string;
  clUnsettledPaymentPlans: any[];
  clUnsettledPaymentPlanMetadataMap: UnsettledPaymentPlanMetadataMap;
  clTotal: number;
  clTotalRows: number;
  clInsurersCountryCode: any;
  clInsurersSelectedItem: any[];
  clPayersSelectedItem: any[];
  clInsurersOptions: any[];
  clHightlightCount: number;

  clGrecoCompanyNumbers: number[];
  getInsurersByCountryStatus: LoadStatus;

  clSearchTerm: string;
  clServiceSegmentCode: number;
  clFromDateFilter: Date;
  clToDateFilter: Date;
  clientSaldo: any;
  clientSaldoAmount: any;
  clientSaldoItems: any[];
  clPoliciesSelectedItem: any[];
  clSubpoliciesSelectedItem: any[];
  clServiceSegmentsSelectedItem: any[];
  clClientsSelectedItem: any[];
  clCurrencyCodesItems: any[];
  clCurrencyCodesSelectedItem: any;
  clPaymentReferenceNumbersSelectedItem: any[];
  clIsBrokerPremiumCollection: boolean;
  clearanceRequest: any;
  clientBalancesSelectAll: boolean;

  clientBalancesSort: any;
  infoClientBalancesSort: any;
  clearClientBalancesSort: any;

  infoClearanceBalanceEntryStatus: LoadStatus;
  infoClearanceDialog: {
    isOpen: boolean;
    clientBalanceId: any;
  };
  infoSettledPayments: any[];
  infoSettledPaymentsMetadataMap: SettledPaymentMetadataMap;
};
// This is the value of the store on initialisation
const initialState: StoreState = {
  notifications: [],

  clientBalance: null,
  clientBalances: [],
  clientBalancesMetadataMap: {},
  clientBalancesLoadStatus: "none",
  clientBalancesSelectAll: false,

  searchClientsSelectedItem: null,
  searchCurrencyItem: null,
  searchTerm: "",
  showZeroBalanceClients: false,

  clearClientSaldoDialog: {
    isOpen: false,
    clientName: "",
  },
  clPaymentDate: null,
  clComment: "",
  clInsurersSelectedItem: [],
  clSearchTerm: "",
  clServiceSegmentCode: null,
  clUnsettledPaymentPlans: [],
  clInsurersOptions: [],
  getInsurersByCountryStatus: "none",
  clInsurersCountryCode: null,
  clPoliciesSelectedItem: [],
  clSubpoliciesSelectedItem: [],
  clGrecoCompanyNumbers: [],
  clServiceSegmentsSelectedItem: [],
  clClientsSelectedItem: [],
  clPayersSelectedItem: [],
  clCurrencyCodesItems: [],
  clCurrencyCodesSelectedItem: null,
  clUnsettledPaymentPlanMetadataMap: {},
  clPaymentReferenceNumbersSelectedItem: [],
  clIsBrokerPremiumCollection: true,
  clHightlightCount: 0,
  clTotal: 0,
  clTotalRows: 0,
  clientSaldo: null,
  clientSaldoItems: [],
  clientSaldoAmount: null,
  clearanceRequest: null,

  clFromDateFilter: moment()
    .subtract(3, "months")
    .add(15, "days")
    .startOf("day")
    .toDate(),
  clToDateFilter: moment().endOf("day").add(15, "days").toDate(),
  clearClientSaldoStatus: "none",

  clientBalancesSort: null,
  infoClientBalancesSort: null,
  clearClientBalancesSort: null,

  infoClearanceDialog: {
    isOpen: false,
    clientBalanceId: null,
  },
  infoSettledPayments: [],
  infoSettledPaymentsMetadataMap: {},
  infoClearanceBalanceEntryStatus: "none",
};

// All the actions that mutate the store
const actions = {
  setClientBalancePageStoreValue: setStoreValue,
  setClientBalancePageStoreValues: setStoreValues,
  loadSettings: loadSettings,
  loadClientBalances: loadClientBalances,
  filterClientBalances: filterClientBalances,
  // filterUnsettledPaymentPlans: filterUnsettledPaymentPlans,
  loadClientBalancePlansForReport: loadClientBalancePlansForReport,
  createSetting: createSetting,
  deleteSetting: deleteSetting,
  updateSetting: updateSetting,
  searchClients: searchClients,
  searchInsurers: searchInsurers,
  generateExcel: generateExcel,
  populateClearanceData: populateClearanceData,
  populateClientBalanceInfoData: populateClientBalanceInfoData,
  getClientSaldo: getClientSaldo,
  clearClientSaldo: clearClientSaldo,
  setNotificationMessage: setNotificationMessage,
  getInsurersByCountry: getInsurersByCountry,
};

const ClientBalancePageStore = createStore({
  initialState,
  actions,
  name: "ClientBalancePageStore",
});
export const useClientBalancePageStore = createHook(ClientBalancePageStore);
export const useClientBalancePageStoreState = createStateHook(
  ClientBalancePageStore
);
export const useClientBalancePageStoreActions = createActionsHook(
  ClientBalancePageStore
);

export const useClInsurersOptions = createHook(ClientBalancePageStore, {
  selector: (state) => ({
    clInsurersOptions: state.clInsurersOptions,
  }),
});

export const useInsurersCountryCode = createHook(ClientBalancePageStore, {
  selector: (state) => ({ insurersCountryCode: state.clInsurersCountryCode }),
});
