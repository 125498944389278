import { ColumnConfig } from "types/bankStatements";

export const clearanceEntriesColumnConfig = (
  scaleFactor: number = 1
): ColumnConfig[] => {
  return [
    {
      columnId: "ordinalNumber",
      width: scaleFactor * 50,
      reorderable: false,
      resizable: true,
      label: "",
      cellType: "ordinalnumber",
      sortable: false,
    },
    {
      columnId: "policyName",
      width: scaleFactor * 250,
      reorderable: true,
      resizable: true,
      label: "pct.policyName.label",
      sortable: true,
      cellType: "customtext",
      additionalInfo: {
        gosLink: {
          idField: "policyId",
          linkType: "policy",
        },
      },
    },
    {
      columnId: "clientName",
      width: scaleFactor * 250,
      reorderable: true,
      resizable: true,
      sortable: true,
      label: "pct.client.label",
      cellType: "customtext",
      additionalInfo: {
        gosLink: {
          idField: "clientId",
          linkType: "client",
        },
      },
    },
    {
      columnId: "insurerNameShort",
      width: scaleFactor * 145,
      reorderable: true,
      resizable: true,
      label: "pct.insurer.label",
      sortable: true,
      cellType: "customtext",
      additionalInfo: {
        gosLink: {
          idField: "insurerId",
          linkType: "client",
        },
      },
    },
    {
      columnId: "payerName",
      width: scaleFactor * 145,
      reorderable: true,
      resizable: true,
      sortable: true,
      label: "pct.payer.label",
      cellType: "customtext",
      additionalInfo: {
        gosLink: {
          idField: "payerId",
          linkType: "payer",
        },
      },
    },

    {
      columnId: "policyNumber",
      width: scaleFactor * 150,
      reorderable: true,
      resizable: true,
      label: "pct.policyNumber.label",
      sortable: true,
      cellType: "customtext",
      additionalInfo: undefined,
    },

    {
      columnId: "policySubNumber",
      width: scaleFactor * 150,
      reorderable: true,
      resizable: true,
      label: "pct.policySubNumber.label",
      sortable: true,
      cellType: "customtext",
      additionalInfo: undefined,
    },
    {
      columnId: "insuranceLineName",
      width: scaleFactor * 250,
      reorderable: true,
      resizable: true,
      label: "pct.insuranceLineCode.label",
      sortable: true,
      cellType: "customtext",
      additionalInfo: undefined,
    },
    {
      columnId: "bookingDate",
      width: scaleFactor * 120,
      reorderable: true,
      resizable: true,
      label: "pct.bookingDate.label",
      sortable: true,
      cellType: "datepicker",
      additionalInfo: undefined,
    },
    {
      columnId: "premiumFrom",
      width: scaleFactor * 120,
      reorderable: true,
      resizable: true,
      label: "pct.premiumFrom.label",
      sortable: true,
      cellType: "datepicker",
      additionalInfo: undefined,
    },
    {
      columnId: "premiumTo",
      width: scaleFactor * 120,
      reorderable: true,
      resizable: true,
      label: "pct.premiumTo.label",
      sortable: true,
      cellType: "datepicker",
      additionalInfo: undefined,
    },

    {
      columnId: "premiumAmount",
      width: scaleFactor * 120,
      reorderable: true,
      resizable: true,
      label: "pct.premiumAmount.label",
      sortable: true,
      cellType: "customnumber",
      additionalInfo: undefined,
    },
    {
      columnId: "premiumDiscount",
      width: scaleFactor * 120,
      reorderable: true,
      resizable: true,
      label: "pct.premiumDiscount.label",
      sortable: true,
      cellType: "customnumber",
      additionalInfo: undefined,
    },
    {
      columnId: "premiumDiscountPercent",
      width: scaleFactor * 120,
      reorderable: true,
      resizable: true,
      label: "pct.premiumDiscountPercent.label",
      sortable: true,
      cellType: "customnumber",
      additionalInfo: undefined,
    },
    {
      columnId: "totalPremiumAmount",
      width: scaleFactor * 120,
      reorderable: true,
      resizable: true,
      label: "pct.totalPremiumAmount.label",
      sortable: true,
      cellType: "customnumber",
      additionalInfo: undefined,
    },
    {
      columnId: "paidPremiumAmount",
      width: scaleFactor * 120,
      reorderable: true,
      resizable: true,
      label: "pct.paidPremiumAmount.label",
      sortable: true,
      cellType: "customnumber",
      additionalInfo: undefined,
    },
    {
      columnId: "paidPremiumDiscountAmount",
      width: scaleFactor * 120,
      reorderable: true,
      resizable: true,
      sortable: true,
      label: "pct.paidPremiumDiscountAmount.label",
      cellType: "customnumber",
      additionalInfo: undefined,
    },
    {
      columnId: "premiumDebt",
      width: scaleFactor * 120,
      reorderable: true,
      resizable: true,
      label: "pct.premiumDebt.label",
      sortable: true,
      cellType: "customnumber",
      additionalInfo: undefined,
    },
    {
      columnId: "premiumDiscountDebt",
      width: scaleFactor * 120,
      reorderable: true,
      resizable: true,
      sortable: true,
      label: "pct.premiumDiscountDebt.label",
      cellType: "customnumber",
      additionalInfo: undefined,
    },
    {
      columnId: "suggestedPaymentAmount",
      width: scaleFactor * 180,
      reorderable: true,
      resizable: true,
      label: "pct.toBeClearedAmount.label",
      sortable: true,
      cellType: "clearedamount",
      additionalInfo: undefined,
    },
  ];
};
