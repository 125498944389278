import Axios, { AxiosError } from "axios";
import moment from "moment";
import { Setting } from "types/types";
import {
  compareNumbersUpToTwoDecimals,
  formatNumberForSearch,
  getShortInsurerName,
  handleAxiosError,
  prepareNotificationObject,
} from "utils/utils";
import * as API from "../api/api";
import { StoreState } from "./ClientBalancePageStore";
import {
  ClientBalancesMetadataMap,
  UnsettledPaymentPlanMetadataMap,
} from "utils/types";
import { setNotificationMessage } from "./AppStoreActions";

export const setStoreValue =
  (name: keyof StoreState, value) =>
  ({ setState }) => {
    setState({
      [name]: value,
    });
  };

export const setStoreValues =
  (values) =>
  ({ setState }) => {
    setState({
      ...values,
    });
  };

function transformList({ originalList, taxonomy, grecoCompanies, t }) {
  const newList = [];

  originalList.forEach((item) => {
    item.clientSaldoAmountAndCurrency.forEach((saldoItem) => {
      const newItem = {
        clientBalanceId:
          item.clientId +
          "" +
          saldoItem.companyNumber +
          "" +
          saldoItem.currencyCode,
        clientId: item.clientId,
        isGrECoClient: item.isGrECoClient,
        isGrEcoPayment: item.isGrECoClient,
        clientInfo: item.clientInfo,
        clientName:
          (item.clientInfo.isCompany ? "" : " ") +
          item.clientInfo.name +
          " (" +
          item.clientInfo.internalNumber +
          ")",
        currencyName: t(taxonomy?.Currency?.byId[saldoItem.currencyCode].code),
        currencyCode: saldoItem.currencyCode,
        companyName: grecoCompanies?.find(
          (gc) => gc.companyRegisterNumber === saldoItem.companyRegisterNumber
        )?.companyShort,
        companyNumber: saldoItem.companyNumber,
        lastModifiedAt: saldoItem.lastModifiedAt,
        clientBalanceAmount: saldoItem.clientSaldoAmount,
      };
      newList.push(newItem);
    });
  });

  return newList;
}

export const loadClientBalances =
  ({
    grecoCompanyNumbers,
    onSuccess = undefined,
    t,
    grecoCompanies,
    taxonomy,
  }) =>
  async ({ setState, getState, dispatch }) => {
    try {
      if (getState().clientBalancesLoadStatus === "loading") return;
      // const showZeroBalanceClients = getState().showZeroBalanceClients;
      setState({
        clientBalancesLoadStatus: "loading",
      });
      const response = await API.loadClientSaldos({
        GrecoCompanyNumbers: grecoCompanyNumbers
          ?.filter((company: any) => company.selected)
          .map((company: any) => company.companyNumber),
      });
      let clientBalances = transformList({
        originalList: response.data,
        t,
        grecoCompanies,
        taxonomy,
      });
      clientBalances = clientBalances.map((el) => {
        return {
          ...el,
          clientName:
            (el.clientInfo.isCompany ? "" : " ") +
            el.clientInfo.name +
            " (" +
            el.clientInfo.internalNumber +
            ")",
        };
      });
      let clientBalancesMetadataMap: ClientBalancesMetadataMap = {};
      clientBalances.forEach((statement) => {
        clientBalancesMetadataMap[statement.clientBalanceId] = {
          selected: false,
          inEditMode: false,
          changes: [],
          editable: false,
          visible: true,
          oldRow: null,
        };
      });
      setState({
        clientBalances,
        clientBalancesMetadataMap,
        clientBalancesLoadStatus: "success",
        clientBalancesSelectAll: false,
      });
      onSuccess && onSuccess();
    } catch (error: any) {
      if (error.code !== "ERR_CANCELED") {
        handleAxiosError(error, (message) => {
          dispatch(
            setNotificationMessage(
              prepareNotificationObject("loadClientBalances", message)
            )
          );
        });
        setState({
          clientBalancesLoadStatus: "error",
        });
      }
    }
  };

export const generateExcel =
  () =>
  async ({ setState, getState, dispatch }) => {
    try {
      if (getState().generateExcelStatus === "loading") return;
      setState({
        generateExcelStatus: "loading",
      });

      const response = await API.generateExcel({
        ClientIds: getState().searchClientsSelectedItem?.value?.id
          ? [getState().searchClientsSelectedItem?.value?.id]
          : [],
        InsurerIds: getState().searchInsurersSelectedItem?.value?.id
          ? [getState().searchInsurersSelectedItem?.value?.id]
          : [],
        From: getState().fromDateFilter
          ? moment(getState().fromDateFilter).format("YYYY-MM-DD")
          : null,
        To: getState().toDateFilter
          ? moment(getState().toDateFilter).format("YYYY-MM-DD")
          : null,
        ServiceSegmentCodes: getState().searchServiceSegmentItem
          ? [getState().searchServiceSegmentItem]
          : [],
        IsBrokerPremiumCollection: false,
        CurrencyCode: Number(getState().searchCurrencyItem),
        PolicyNumbers:
          getState().searchPolicyNumber && getState().searchPolicyNumber !== ""
            ? [getState().searchPolicyNumber]
            : [],
        PolicySubnumbers: [],
        DateFilterField: getState().searchDateFilterField,
      });

      setState({
        excelReportResponse: response.data,
        generateExcelStatus: "success",
      });
      // onSuccess && onSuccess(response.headers["usertype"]);
    } catch (error: any) {
      if (error.code !== "ERR_CANCELED") {
        handleAxiosError(error, (message) => {
          dispatch(
            setNotificationMessage(
              prepareNotificationObject("generateExcel", message)
            )
          );
        });
        setState({
          generateExcelStatus: "error",
        });
      }
    }
  };

export const filterClientBalances =
  (payload) =>
  ({ setState, getState, dispatch }) => {
    const { searchTerm } = payload;
    let clientBalances = getState().clientBalances as any[];
    let clientBalancesMetadataMap = {
      ...getState().clientBalancesMetadataMap,
    } as ClientBalancesMetadataMap;

    let showZeroBalanceClients = getState().showZeroBalanceClients;

    clientBalances.forEach((el) => {
      let isMatch = true;

      if (
        !showZeroBalanceClients &&
        compareNumbersUpToTwoDecimals(el.clientBalanceAmount, 0) === 0
      ) {
        isMatch = false;
      }

      if (searchTerm && searchTerm !== "") {
        isMatch =
          isMatch &&
          (el.clientName
            ?.toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
            el.companyName
              ?.toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            formatNumberForSearch(el.clientBalanceAmount).includes(
              searchTerm.toLowerCase()
            ) ||
            el.currencyName
              ?.toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            (el.lastModifiedAt
              ? moment(el.lastModifiedAt).format("DD.MM.YYYY")
              : ""
            ).includes(searchTerm.toLowerCase()));
      }

      clientBalancesMetadataMap[el.clientBalanceId].visible = isMatch;
    });
    // console.log(
    //   "IN " + searchTerm,
    //   "visible clientBalancesMetadataMap",
    //   Object.values(clientBalancesMetadataMap).reduce((acc: number, obj: any) => {
    //     return obj.visible === true ? acc + 1 : acc;
    //   }, 0)
    // );
    setState({
      clientBalancesMetadataMap: clientBalancesMetadataMap,
    });
  };

// export const filterUnsettledPaymentPlans =
//   (payload) =>
//   ({ setState, getState, dispatch }) => {
//     const { searchTerm } = payload;
//     let unsettledPaymentPlanEntries = getState()
//       .clUnsettledPaymentPlans as any[];
//     let clUnsettledPaymentPlanMetadataMap = {
//       ...getState().clUnsettledPaymentPlanMetadataMap,
//     } as UnsettledPaymentPlanMetadataMap;

//     unsettledPaymentPlanEntries?.forEach((el) => {
//       let isMatch = true;

//       if (compareNumbersUpToTwoDecimals(el.clientBalanceAmount, 0) === 0) {
//         isMatch = false;
//       }

//       if (searchTerm && searchTerm !== "") {
//         isMatch =
//           isMatch &&
//           (el.clientName
//             ?.toString()
//             .toLowerCase()
//             .includes(searchTerm.toLowerCase()) ||
//             el.companyName
//               ?.toString()
//               .toLowerCase()
//               .includes(searchTerm.toLowerCase()) ||
//             formatNumberForSearch(el.clientBalanceAmount).includes(
//               searchTerm.toLowerCase()
//             ) ||
//             el.currencyName
//               ?.toString()
//               .toLowerCase()
//               .includes(searchTerm.toLowerCase()) ||
//             (el.lastModifiedAt
//               ? moment(el.lastModifiedAt).format("DD.MM.YYYY")
//               : ""
//             ).includes(searchTerm.toLowerCase()));
//       }

//       clUnsettledPaymentPlanMetadataMap[el.paymentPlanEntryId].highlighted =
//         isMatch;
//     });
//     // console.log(
//     //   "IN " + searchTerm,
//     //   "visible clientBalancesMetadataMap",
//     //   Object.values(clientBalancesMetadataMap).reduce((acc: number, obj: any) => {
//     //     return obj.visible === true ? acc + 1 : acc;
//     //   }, 0)
//     // );
//     setState({
//       clUnsettledPaymentPlanMetadataMap: clUnsettledPaymentPlanMetadataMap,
//     });
//   };

export const getInsurersByCountry =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState, dispatch }) => {
    if (getState().getInsurersByCountryStatus === "loading") return;
    try {
      setState({
        getInsurersByCountryStatus: "loading",
      });
      const res = await API.getInsurersByCountry(payload);
      setState({
        getInsurersByCountryStatus: "success",
      });
      onSuccess && onSuccess(res.data);
    } catch (error) {
      handleAxiosError(error, (message) => {
        dispatch(
          setNotificationMessage(
            prepareNotificationObject("getInsurersByCountry", message)
          )
        );
      });
      setState({
        getInsurersByCountryStatus: "error",
      });
      onError && onError(error);
    }
  };
export const populateClearanceData =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState, dispatch }) => {
    const {
      clientId,
      clientIds,
      taxonomy,
      fromDate,
      toDate,
      searchTerm,
      serviceSegmentCodes,
      insurerIds,
      payerIds,
      currencyCode,
      policyNumbers,
      policySubnumbers,
      isBrokerPremiumCollection,
      paymentReferenceNumbers,
      grecoCompanyNumbers,
      t,
    } = payload;

    setState({
      clearClientSaldoStatus: "loading",
    });

    const saldo = await API.getClientSaldo({
      clientId: clientId,
    });

    const clearanceRequest = {
      clientId: clientId,
      clientIds: clientIds,
      payerIds: payerIds,
      serviceSegmentCodes: serviceSegmentCodes,
      From: fromDate ? moment(fromDate).format("YYYY-MM-DD") : null,
      To: toDate ? moment(toDate).format("YYYY-MM-DD") : null,
      PremiumPaymentMeanCodeId:
        taxonomy.PremiumPaymentMean.byCode[
          "PremiumPaymentMean.PREMIUMCOLLECTION"
        ].id,
      insurerIds: insurerIds,
      currencyCode: currencyCode,
      policyNumbers: policyNumbers,
      policySubnumbers: policySubnumbers,
      PaymentReferenceNumbers: paymentReferenceNumbers,
      isBrokerPremiumCollection: isBrokerPremiumCollection,
      GrecoCompanyNumbers: grecoCompanyNumbers,
      MaxResults: 5000,
    };
    const res = await API.suggestClientSaldoClearance(clearanceRequest);

    let unsettledPaymentPlans = res.data.map((el) => {
      return {
        ...el,
        insuranceLineName: t(
          taxonomy?.InsuranceLine?.byId[el["insuranceLineCode"]].code
        ),
        clientName:
          (el.clientName
            ? el.clientName
            : " " + el.clientLastName + " " + el.clientFirstName) +
          " (" +
          el.clientInternalNumber +
          ")",
        insurerNameShort:
          getShortInsurerName(el.insurerId + "") === ""
            ? el.insurerName
            : getShortInsurerName(el.insurerId + "") +
              " (" +
              el.insurerInternalNumber +
              ")",
      };
    });
    // filter list by search term
    if (searchTerm) {
      unsettledPaymentPlans = unsettledPaymentPlans.filter((el) => {
        return (
          el.policyName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          el.insurerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          el.policyNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
          el.policySubNumber.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
    }
    // add default metadata to each row to clUnsettledPaymentPlanMetadataMap
    const metadataMap = {};
    unsettledPaymentPlans.forEach((el) => {
      metadataMap[el.paymentPlanEntryId] = {
        visible: true,
        // selected: false,
        // edited: false,
        editable: false,
        changes: [],
      };
    });
    let total = unsettledPaymentPlans.reduce(
      (acc, el) => acc + (el.suggestedPaymentAmount ?? 0),
      0
    );
    let totalRows = unsettledPaymentPlans.reduce(
      (acc, el) => acc + (el.suggestedPaymentAmount > 0 ? 1 : 0),
      0
    );

    //totalRow.premiumDebt = bankStatementEntry.amount;
    // if (unsettledPaymentPlans.length > 0) {
    //   unsettledPaymentPlans = [...unsettledPaymentPlans, totalRow];
    // }
    setState({
      clUnsettledPaymentPlans: unsettledPaymentPlans,
      clUnsettledPaymentPlanMetadataMap: metadataMap,
      clientSaldo: saldo.data,
      clientSaldoAmount: saldo?.data?.clientSaldoAmountAndCurrency?.find(
        (el) =>
          el.currencyCode === currencyCode &&
          el.companyNumber === Number(grecoCompanyNumbers[0])
      ).clientSaldoAmount,
      clTotal: total,
      clTotalRows: totalRows,
      clearanceRequest: clearanceRequest,
      clearClientSaldoStatus: "success",
    });
  };

export const getClientSaldo =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState, dispatch }) => {
    const { clientId } = payload;
    setState({
      getClientSaldoStatus: "loading",
    });
    try {
      const res = await API.getClientSaldo({
        clientId: clientId,
      });

      setState({
        clientSaldo: res.data,

        getClientSaldoStatus: "success",
      });
      onSuccess && onSuccess(res.data);
    } catch (error) {
      handleAxiosError(error, (message) => {
        dispatch(
          setNotificationMessage(
            prepareNotificationObject("getClientSaldo", message)
          )
        );
      });
      setState({
        getClientSaldoStatus: "error",
      });
      onError && onError(error);
    }
  };

export const populateClientBalanceInfoData =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState, dispatch }) => {
    const { clientBalanceId, clientId, companyNumber, currencyCode } = payload;
    const clientBalance = getState().clientBalances.find(
      (el) => el.clientBalanceId === clientBalanceId
    ) as any;
    if (clientBalance) {
      setState({
        infoClearanceBalanceEntryStatus: "loading",
      });
      const res = await API.populateClientBalanceClearanceInfo({
        clientId,
        companyNumber,
        currencyCode,
      });
      let settledPayments = res.data;

      const infoSettledPaymentsMetadataMap = {};
      settledPayments.forEach((el) => {
        infoSettledPaymentsMetadataMap[el.paymentId] = {
          visible: true,
          selected: false,
          editable: false,
          changes: [],
        };
      });

      setState({
        infoSettledPayments: settledPayments,
        infoSettledPaymentsMetadataMap: infoSettledPaymentsMetadataMap,
        infoClearanceBalanceEntryStatus: "success",
      });
    }
  };

export const clearClientSaldo =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState, dispatch }) => {
    const {
      clientId,
      clearanceRequest,
      suggestedPayments,
      comment,
      paymentDate,
    } = payload;
    setState({
      clearClientSaldoStatus: "loading",
    });
    try {
      const res = await API.commitClientSaldoClearance({
        clientId,
        clearanceRequest,
        suggestedPayments,
        comment,
        paymentDate,
      });
      // const updatedBankStatementEntry = res.data;

      // let statements = [...getState().bankStatements];
      // statements.forEach((el) => {
      //   if (el.bankStatementId === updatedBankStatementEntry.bankStatementId) {
      //     el.bankStatementEntries.forEach((entry) => {
      //       if (
      //         entry.bankStatementEntryId ===
      //         updatedBankStatementEntry.bankStatementEntryId
      //       ) {
      //         entry = updatedBankStatementEntry;
      //       }
      //     });
      //   }
      // });

      setState({
        //   bankStatementEntries: [
        //     ...getState().bankStatementEntries.map((el) =>
        //       el.bankStatementEntryId ===
        //       updatedBankStatementEntry.bankStatementEntryId
        //         ? {
        //             selected: true,
        //             edited: false,
        //             changes: [],
        //             ...updatedBankStatementEntry,
        //           }
        //         : el
        //     ),
        //   ],
        //bankStatements: statements,
        clearClientSaldoStatus: "success",
      });

      onSuccess && onSuccess(res.data);
    } catch (error) {
      handleAxiosError(error, (message) => {
        dispatch(
          setNotificationMessage(
            prepareNotificationObject("clearClientSaldo", message)
          )
        );
      });
      setState({
        clearClientSaldoStatus: "error",
      });
      onError && onError(error);
    }
  };
export const loadClientBalancePlansForReport =
  (payload) =>
  async ({ setState, getState, dispatch }) => {
    const { onSuccess, onError, grecoCompanyNumbers } = payload;
    const cancelSource = Axios.CancelToken.source();

    try {
      if (getState().clientBalancesLoadStatus === "loading") return;
      setState({
        clientBalancesLoadStatus: "loading",
      });

      // const response = await API.clientBalanceQuery({
      //   ClientIds: getState().searchClientsSelectedItem?.value?.id
      //     ? [getState().searchClientsSelectedItem?.value?.id]
      //     : [],
      //   InsurerIds: getState().searchInsurersSelectedItem?.value?.id
      //     ? [getState().searchInsurersSelectedItem?.value?.id]
      //     : [],
      //   From: getState().fromDateFilter
      //     ? moment(getState().fromDateFilter).format("YYYY-MM-DD")
      //     : null,
      //   To: getState().toDateFilter
      //     ? moment(getState().toDateFilter).format("YYYY-MM-DD")
      //     : null,
      //   ServiceSegmentCodes: getState().searchServiceSegmentItem
      //     ? [getState().searchServiceSegmentItem]
      //     : [],
      //   IsBrokerPremiumCollection: false,
      //   CurrencyCode: Number(getState().searchCurrencyItem),
      //   PolicyNumbers:
      //     getState().searchPolicyNumber && getState().searchPolicyNumber !== ""
      //       ? [getState().searchPolicyNumber]
      //       : [],
      //   PolicySubnumbers: [],
      //   GrecoCompanyNumbers: grecoCompanyNumbers
      //     ?.filter((company: any) => company.selected)
      //     .map((company: any) => company.companyNumber),
      // });
      // let clientBalances = response.data.filter((el) => el.isDeleted === false);
      // let clientBalancesMetadataMap: ClientBalancesMetadataMap = {};

      // clientBalances.forEach((statement) => {
      //   clientBalancesMetadataMap[statement.clientBalanceId] = {
      //     selected: false,
      //     inEditMode: false,
      //     changes: [],
      //     deleted: statement.isDeleted,
      //     editable: false,
      //     visible: true,
      //     oldRow: null,
      //   };
      // });

      // setState({
      //   clientBalances,
      //   clientBalancesMetadataMap,
      //   clientBalancesLoadStatus: "success",
      // });
      // onSuccess && onSuccess(response.headers["usertype"]);
    } catch (error: any) {
      if (error.code !== "ERR_CANCELED") {
        handleAxiosError(error, (message) => {
          dispatch(
            setNotificationMessage(
              prepareNotificationObject(
                "loadClientBalancePlansForReport",
                message
              )
            )
          );
        });
        setState({
          clientBalancesLoadStatus: "error",
        });
      }
    }
  };

// export const clearClientBalance =
//   (payload, onSuccess?, onError?) =>
//   async ({ setState, getState, dispatch }) => {
//     if (getState().invalidateClientBalancesStatus === "loading") return;
//     try {
//       setState({
//         invalidateClientBalancesStatus: "loading",
//       });
//       const res = await API.clearClientBalances(payload);
//       setState({
//         invalidateClientBalancesStatus: "success",
//       });
//       onSuccess && onSuccess(res.data);
//     } catch (error) {
//       handleAxiosError(error, (message) => { dispatch(setNotificationMessage(prepareNotificationObject("clearClientBalance", message)));});
//       setState({
//         invalidateClientBalancesStatus: "error",
//       });
//       onError && onError(error);
//     }
//   };

export const loadSettings =
  (applicationCodeId) =>
  async ({ setState, getState, dispatch }) => {
    if (getState().settingsLoadStatus === "loading") return;
    try {
      setState({
        settingsLoadStatus: "loading",
      });
      const res = await API.getAppSettings(applicationCodeId);
      setState({
        settings: res.data,
        settingsLoadStatus: "success",
      });
      const defaultView = res.data.find((s) => s.isDefault);
      if (defaultView) {
        setState({
          selectedViewId: defaultView.userAppSettingId,
        });
      }
    } catch (err) {
      handleAxiosError(err, (message) => {
        dispatch(
          setNotificationMessage(
            prepareNotificationObject("loadSettings", message)
          )
        );
      });
      setState({
        settingsLoadStatus: "error",
      });
    }
  };

export const searchClients =
  (payload) =>
  async ({ setState, getState, dispatch }) => {
    const { onSuccess, onError, searchTerm } = payload;
    const cancelSource = Axios.CancelToken.source();
    try {
      if (getState().clientsLoadStatus === "loading") return;
      setState({
        searchClientsLoadStatus: "loading",
      });
      const response = await API.searchClients(searchTerm);
      setState({
        searchClientsList: response.data,
        searchClientsLoadStatus: "success",
      });
      onSuccess && onSuccess(response.data);
    } catch (error: any) {
      onError && onError(error);
      if (error.code !== "ERR_CANCELED") {
        handleAxiosError(error, (message) => {
          dispatch(
            setNotificationMessage(
              prepareNotificationObject("searchClients", message)
            )
          );
        });
        setState({
          searchClientsLoadStatus: "error",
        });
      }
    }
  };

export const searchInsurers =
  (payload) =>
  async ({ setState, getState, dispatch }) => {
    const { onSuccess, onError, searchTerm } = payload;
    const cancelSource = Axios.CancelToken.source();
    try {
      if (getState().insurersLoadStatus === "loading") return;
      setState({
        searchInsurersLoadStatus: "loading",
      });
      const response = await API.searchInsurers(searchTerm);
      setState({
        searchInsurersList: response.data,
        searchInsurersLoadStatus: "success",
      });
      onSuccess && onSuccess(response.data);
      return response.data;
    } catch (error: any) {
      onError && onError(error);
      if (error.code !== "ERR_CANCELED") {
        handleAxiosError(error, (message) => {
          dispatch(
            setNotificationMessage(
              prepareNotificationObject("searchInsurers", message)
            )
          );
        });
        setState({
          searchInsurersLoadStatus: "error",
        });
      }
    }
  };

export const createSetting =
  (newSetting: Setting) =>
  async ({ setState, getState, dispatch }) => {
    if (getState().settingsLoadStatus === "loading") return;
    try {
      setState({
        settingsLoadStatus: "loading",
      });
      const res = await API.addAppSetting(newSetting);
      const allSettings = res.data;
      setState({
        settings: allSettings,
        settingsLoadStatus: "success",
      });
      const newSettingId = allSettings.find(
        (s) => s.userAppSettingName === newSetting.userAppSettingName
      )?.userAppSettingId;
      if (newSettingId) {
        setState({
          selectedViewId: newSettingId,
        });
      }
      // toast.success(i18next.t("greco.success"));
    } catch (err) {
      handleAxiosError(err, (message) => {
        dispatch(
          setNotificationMessage(
            prepareNotificationObject("createSetting", message)
          )
        );
      });
      setState({
        settingsLoadStatus: "error",
      });
    }
  };

export const deleteSetting =
  (id: number) =>
  async ({ setState, getState, dispatch }) => {
    if (getState().settingsLoadStatus === "loading") return;
    try {
      setState({
        settingsLoadStatus: "loading",
      });
      const res = await API.deleteAppSetting(id);
      const allSettings = res.data;
      setState({
        settings: allSettings,
        settingsLoadStatus: "success",
      });
      // toast.success(i18next.t("greco.success"));
    } catch (err) {
      handleAxiosError(err, (message) => {
        dispatch(
          setNotificationMessage(
            prepareNotificationObject("deleteSetting", message)
          )
        );
      });
      setState({
        settingsLoadStatus: "error",
      });
    }
  };

export const updateSetting =
  (setting: Setting) =>
  async ({ setState, getState, dispatch }) => {
    if (getState().settingsLoadStatus === "loading") return;
    try {
      setState({
        settingsLoadStatus: "loading",
      });
      const res = await API.updateAppSetting(setting);
      const allSettings = res.data;
      setState({
        settings: allSettings,
        settingsLoadStatus: "success",
      });
      // toast.success(i18next.t("greco.success"));
    } catch (err) {
      handleAxiosError(err, (message) => {
        dispatch(
          setNotificationMessage(
            prepareNotificationObject("updateSetting", message)
          )
        );
      });
      setState({
        settingsLoadStatus: "error",
      });
    }
  };
